import React, { useState } from 'react';
import RadioBoxInput from '../../../../components/RadioBoxInput';
import Button from '../../../../components/Button';

import { useAuth } from '../../../../hooks/useAuth';
import { Controller, useForm } from 'react-hook-form';
import AsideModal from '../../../../components/AsideModal';
import ShowNpnInfo from '../../../Claims/View/ShowNpnInfo';
import { CopyIcon, TickCircleIcon } from '../../../../assets/icons/useIcons';

import CodeWebhook from './Components/CodeWebhook';
import WebhookTable from './Components/WebhookTable';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { webhookUpdateMode } from '../../actions';

const webhoookModeArr = [
  {
    type: 'TEST',
    label: 'Test',
  },
  { type: 'LIVE', label: 'Live' },
];

const Webhook = () => {
  const { user } = useAuth();
  const [openModal, setOpenModal] = useState('');
  const [copied, setCopied] = useState(false);
  const queryClient = useQueryClient();
  const { control, watch } = useForm({
    defaultValues: { format: 'JSON', webhookMode: '' },
    mode: 'onSubmit',
  });

  let [formatWatch] = watch(['format']);

  function handleClose() {
    setOpenModal('');
  }

  const modalLookUp = {
    showInfoNpn: {
      component: <ShowNpnInfo handleClose={handleClose} />,
      title: 'Webhook Result',
    },
  };

  const handleCopy = (copyText) => {
    navigator.clipboard
      .writeText(copyText)
      .then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 1000);
      })
      .catch((err) => {
        console.error('Failed to copy text: ', err);
      });
  };

  const { mutate, isLoading } = useMutation({
    mutationFn: webhookUpdateMode,
    mutationKey: 'webhookUpdateMode',
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['getUserDetails'],
        exact: false,
      });
    },
  });

  return (
    <div className="d--flex flex--column gap--lg">
      <div className="d--flex gap--lg w--full h--full flex--column-xs">
        <div className="webhook w--full radius--sm border-full--black-100 p--lg h--full  h-min--440 ">
          <div className=" font--md font--600 m-b--lg">
            Choose your payload format
          </div>
          <div className="d--flex gap--xl flex--column">
            <div className="d--flex gap--md p-b--lg border-bottom--black-100 align-items--start">
              <div className="m-t--xs d--flex">
                <Controller
                  name="format"
                  control={control}
                  render={({ field }) => (
                    <RadioBoxInput
                      {...field}
                      htmlFor="JSON"
                      value="JSON"
                      checked={formatWatch === 'JSON'}
                    />
                  )}
                />
              </div>
              <div className="w--full">
                <div className="m-b--sm font--500">JSON</div>
                <div>
                  The request is sent using the PUT/POST HTTP method in JSON
                  format
                </div>
              </div>
            </div>
            {/* ------ */}
            <div className="d--flex gap--md p-b--lg border-bottom--black-100 align-items--start">
              <div className="m-t--xs d--flex">
                <Controller
                  name="format"
                  control={control}
                  render={({ field }) => (
                    <RadioBoxInput
                      {...field}
                      htmlFor="FORMDATA"
                      value="FORMDATA"
                      checked={formatWatch === 'FORMDATA'}
                    />
                  )}
                />
              </div>
              <div className="w--full">
                <div className="m-b--sm font--500">FORM DATA</div>
                <div>
                  The request is sent using the PUT/POST HTTP method containing
                  form-encoded data
                </div>
              </div>
            </div>

            <div className="d--flex gap--md flex--column ">
              <div className=" font--md font--600">
                Configure this webhook URL in your app or code
              </div>
              <div className="d--flex gap--md p-t---md h-min--60 radius--sm border-full--black-100 word-break--all p--md position--relative">
                {`https://conxent-api.nextere.com/webhook/outbound?conxentkey=${user?.webhook}`}
                <div
                  className="position--absolute top---13  right---4 c--pointer text--black-600 bg--white"
                  onClick={() =>
                    handleCopy(
                      `https://conxent-api.nextere.com/webhook/outbound?conxentkey=${user?.webhook}`,
                    )
                  }
                >
                  {copied ? <TickCircleIcon /> : <CopyIcon />}
                </div>
              </div>
              {/* ------ */}
              {/* <p>This is the webhook we will send consent data to you</p> */}
              <div className="d--flex justify-content--end w--full">
                <div className="w-max--150 d--flex w--full bg--primary border-full--primary radius--md ">
                  {webhoookModeArr.map(({ type, label }) => (
                    <Button
                      key={type}
                      type="button"
                      btnClasses="btn btn--p-0"
                      color="black"
                      borderColor="primary"
                      isOutline
                      size="xs"
                      variant={type === user?.webhookMode ? 'white' : 'primary'}
                      onClick={() => {
                        if (type !== user?.webhookMode) {
                          mutate({
                            webhookType: 'OUTBOUND',
                            webhookMode: type,
                          });
                        }
                      }}
                      disabled={type !== user?.webhookMode ? isLoading : false}
                    >
                      {label}
                    </Button>
                  ))}
                </div>
              </div>
            </div>
          </div>
          {openModal ? (
            <AsideModal
              handleClose={handleClose}
              title={modalLookUp?.[openModal]?.title}
              footerComponent={null}
            >
              {modalLookUp?.[openModal]?.component}
            </AsideModal>
          ) : null}
        </div>

        <CodeWebhook />
      </div>

      <WebhookTable />
    </div>
  );
};

export default Webhook;
