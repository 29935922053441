import React, { useState } from 'react';
import Congratulations from '../../../assets/images/party-popper.png';
import Button from '../../../components/Button';
import { useMutation } from '@tanstack/react-query';
import OtpTimer from '../../OTPVerify/OtpTimer';
import { useAlert } from '../../../hooks/useAlert';
import { useAuth } from '../../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { MEMBER } from '../../../helpers/constants';
import nextereLogo from '../../../assets/images/nextere-logo.png';
import { linkCodeApi, sendLinkToMobileApi } from '../../SignUp/actions';
import PublicSocket from '../../../context/PublicSocket';
import { getOS } from '../../../helpers/functions';

const LoginCongrats = ({ responseData }) => {
  const { phone, uniqueCode } = responseData || {};

  const [time, setTime] = useState(0);
  const { showAlert } = useAlert();
  const { setUser, setUserData } = useAuth();
  const navigate = useNavigate();

  const { mutate: linkCodeApiMutate } = useMutation({
    mutationFn: linkCodeApi,
    onSuccess: ({ data }) => {
      showAlert({ type: 'success', message: data?.data?.message });

      setUser({
        token: data?.data?.token?.token,
        role: data?.data?.role,
      });
      setUserData(data?.data);
      navigate(data?.data?.role === MEMBER ? '/agents' : '/dashboard');
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'danger', message: errMsg });
    },
  });
  const { mutate: sendLinkToMobileApiMutate } = useMutation({
    mutationFn: sendLinkToMobileApi,
    onSuccess: ({ data }) => {
      showAlert({ type: 'success', message: data?.data?.message });
      setTime(120);
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'danger', message: errMsg });
    },
  });
  return (
    <PublicSocket uniqueCode={uniqueCode}>
      <div className="w--full  d--flex flex--column align-items--center justify-content--start loginPage bg--contrast overflow--y-auto">
        <div className="w--full h-min--60 d--flex justify-content--between align-items--center bg--white p-t--xs p-l--md p-r--md border-bottom--black-50">
          <img src={nextereLogo} alt="nextereLogo" className="w-max--150" />
        </div>
        <div className="w--full h--full p-t--xl d--flex align-items--center justify-content--center p-b--xl flex--column loginContent p-l--sm p-r--sm">
          <div className="radius--md  gap--xl w-max--600 w--full bg--secondary p--xl z-index--xs bg--white box--shadow d--flex align-items--center justify-content--center flex--column gap--xs h-min--300">
            <div className="w--full d--flex  justify-content--center flex--column">
              <img
                src={Congratulations}
                alt="..."
                className="w--full w-max--100 m-b--xl"
              />
              <h2 className="font--xl  m-b--md text--success">
                Congratulations!
              </h2>
              {/* <h3 className="font--lg  m-b--md ">
                Your account has been created successfully and account
                credentials has been sent to your email.
              </h3> */}
              <h5 className="font--md font--400  m-b--md">
                Before you start using your account, we need your ID and face
                verification. Make sure you have camera attached to your
                Desktop/Laptop to do this step or you can proceed using your
                mobile browser.
              </h5>
              <h5 className="font--md font--400  m-b--md">
                If you want to further proceed using your mobile camera, please
                click below to request verification link by SMS on your mobile
                number XXXXXX
                {phone?.slice(-4)}.
              </h5>
              <div className="d--flex justify-content--center align-items--center gap--sm flex--column-xs">
                {['windows', 'macos'].includes(getOS()) && (
                  // {getOS() === 'windows' || getOS() === 'macos' && (
                  <Button
                    type="button"
                    btnClasses="btn w-max--200"
                    size="md"
                    variant="secondary-100"
                    borderColor="secondary"
                    color="black"
                    isOutline
                    data-link-hover-secondary
                    onClick={() =>
                      sendLinkToMobileApiMutate({ phone, uniqueCode })
                    }
                    disabled={time > 0}
                  >
                    Send me link on mobile
                  </Button>
                )}
                <Button
                  type="button"
                  btnClasses="btn "
                  size="md"
                  variant="secondary-100"
                  borderColor="secondary"
                  color="black"
                  isOutline
                  data-link-hover-secondary
                  onClick={() => linkCodeApiMutate({ uniqueCode })}
                >
                  I have a Camera, Continue
                </Button>
              </div>
              {time > 0 && (
                <div className=" d--flex gap--sm font--2sm text--danger m-t--sm font--500">
                  Resend in <OtpTimer {...{ time, setTime }} />
                  sec
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </PublicSocket>
  );
};

export default LoginCongrats;
