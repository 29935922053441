import React from 'react';
import FormSelect from '../../../components/FormSelect';
import { useForm, Controller } from 'react-hook-form';
import RadioBoxInput from '../../../components/RadioBoxInput';
import Button from '../../../components/Button';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getAgencyListDropDown, sendInvite } from '../actions';
import { useAlert } from '../../../hooks/useAlert';
import { requiredFieldFunction } from '../../../helpers/yupValidations';
import { yupResolver } from '@hookform/resolvers/yup';
import { object } from 'yup';

let agencyTypeLookUp = {
  OWN_AGENCY: 'Own Agency',
  ASSOCIATED_AGENCY: 'Associated Agency',
  INVITED_AGENCY: 'Invited Agency',
};

export const schema = object().shape({
  // agency: requiredFieldFunction('Agency'),
  reason: requiredFieldFunction('Reason'),
  type: requiredFieldFunction('Type'),
  language: requiredFieldFunction('Language'),
  enrollmentYear: requiredFieldFunction('Enrollment Year'),
});

const SendDocModal = ({
  handleClose,
  uuid,
  isSigned,
  editMemberInstance = {},
}) => {
  let options = [
    { label: 'CMS Consent', value: 'CMS', access: true },
    {
      label: 'Eligibility Review',
      value: 'ELIGIBILITY',
      access: editMemberInstance?.editData?.signedDate ? true : false,
    },
  ].filter((val) => {
    if (
      val?.value === 'ELIGIBILITY' &&
      !editMemberInstance?.editData?.signedDate
    ) {
      return '';
    }
    return val;
  });
  const queryclient = useQueryClient();
  const { showAlert } = useAlert();

  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      reason: 'CONSENT',
      agency: '',
      type: isSigned ? 'ELIGIBILITY' : 'CMS',
      language: 'en',
      enrollmentYear: '2024',
    },
    resolver: yupResolver(schema),
    mode: 'onSubmit',
  });

  const { data: agencyListDropdown } = useQuery({
    queryKey: 'getAgencyListDropDown',
    queryFn: getAgencyListDropDown,
    select: (data) => data?.data?.data,
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: sendInvite,
    mutationKey: 'sendInvite',
    onSuccess: ({ data }) => {
      showAlert({ type: 'success', message: data?.data?.message });
      queryclient.invalidateQueries({
        queryKey: ['getMemberList'],
        exact: false,
      });
      handleClose();
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'danger', message: errMsg });
    },
  });

  const onSubmit = (_) => mutate({ ..._, uuid });

  return (
    <form
      className="w--full d--flex flex--column gap--xl "
      onSubmit={handleSubmit(onSubmit)}
      onClick={(e) => {
        e.stopPropagation();
      }}
      autoComplete="off"
    >
      <div className="d--flex gap--lg w--full">
        <div className="w--full">
          <Controller
            name="reason"
            control={control}
            render={({ field }) => (
              <FormSelect
                {...field}
                label="Reason"
                error={errors?.reason?.message}
                options={[
                  {
                    label: 'Consent',
                    value: 'CONSENT',
                  },
                  {
                    label: 'App Review',
                    value: 'APP_REVIEW',
                  },
                  {
                    label: 'Change Approval',
                    value: 'CHANGE_APPROVAL',
                  },
                  {
                    label: 'Multi Type',
                    value: 'MULTI_TYPE',
                  },
                ]}
                height="40"
              />
            )}
          />
        </div>
      </div>

      <div className="d--flex gap--lg w--full">
        <div className="w--full">
          {' '}
          <Controller
            name="enrollmentYear"
            control={control}
            render={({ field }) => (
              <FormSelect
                {...field}
                label="Enrollment Year"
                error={errors?.enrollmentYear?.message}
                options={[
                  {
                    label: '2024',
                    value: '2024',
                  },
                ]}
                // options={yearsList}
                height="40"
              />
            )}
          />
        </div>
        <div className="w--full">
          <Controller
            name="agency"
            control={control}
            render={({ field }) => (
              <FormSelect
                {...field}
                placeholder="Select Agency"
                options={
                  agencyListDropdown &&
                  agencyListDropdown?.length > 0 &&
                  agencyListDropdown?.map(
                    ({ agencyName, agencyNPN, type, typeAgency }) => {
                      return {
                        label: `${agencyName} (${agencyTypeLookUp[type]})`,
                        value: `${agencyName}_${agencyNPN}_${typeAgency}`,
                      };
                    },
                  )
                }
                label="Agency"
                error={errors?.agency?.message}
                required={false}
              />
            )}
          />
        </div>
      </div>

      <div className=" w--full d--flex align-items--center justify-content--between gap--lg">
        <label className="label--control font--sm font--500  text--black w-min--100 w--full">
          Document Type
        </label>
        <div className="d--flex gap--md w--full">
          <div className="d--flex gap--sm">
            <input
              {...register('type')}
              type="radio"
              className="form-check-input"
              checked={watch('type') === 'CMS'}
              value="CMS"
            />
            <label className="form-check-label">Consent</label>
          </div>
          {editMemberInstance?.editData?.signedDate && (
            <div className="d--flex gap--sm">
              <input
                {...register('type')}
                type="radio"
                checked={watch('type') === 'ELIGIBILITY'}
                value="ELIGIBILITY"
              />
              <label className="form-check-label">Eligibility</label>
            </div>
          )}
        </div>
      </div>
      <div className=" w--full d--flex align-items--center justify-content--between gap--lg">
        <label className="label--control font--sm font--500  text--black w-min--100 w--full">
          Language
        </label>
        <div className="d--flex gap--md w--full">
          <Controller
            name="language"
            control={control}
            render={({ field }) => (
              <RadioBoxInput
                {...field}
                htmlFor="en"
                label="English"
                value="en"
                checked={watch('language') === 'en'}
              />
            )}
          />
          <Controller
            name="language"
            control={control}
            render={({ field }) => (
              <RadioBoxInput
                {...field}
                htmlFor="es"
                label="Spanish"
                value="es"
                checked={watch('language') === 'es'}
              />
            )}
          />
        </div>
      </div>

      <div className=" w--full d--flex align-items--center justify-content--between gap--lg">
        <label className="label--control font--sm font--500  text--black w-min--100 w--full">
          Send Via
        </label>
        <div className="d--flex gap--md w--full">
          <div className="d--flex gap--sm">
            <input type="checkbox" className="form-check-input" checked />
            <label className="form-check-label">Email</label>
          </div>
          <div className="d--flex gap--sm">
            <input type="checkbox" />
            <label className="form-check-label">SMS</label>
          </div>
        </div>
      </div>
      <div className=" d--flex align-items--center justify-content--end m-t--lg gap--sm">
        <Button
          btnClasses="btn  w-max--150"
          variant="transparent"
          color="black"
          isOutline
          borderColor="primary"
          data-link-hover
          type="button"
          onClick={handleClose}
        >
          Back
        </Button>
        <Button
          btnClasses="btn  w-max--150"
          variant="primary"
          color="black"
          disabled={isLoading}
          type="submit"
        >
          {isLoading ? 'Please wait...' : 'Send'}
        </Button>
      </div>
    </form>
  );
};

export default SendDocModal;
