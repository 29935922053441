import { apiClient } from '../../api/axios';
import { API_ROUTES } from './constants';

export const getDashboardSates = (data) => {
  return apiClient({
    method: API_ROUTES.DASHBOARD_SATES.METHOD,
    url: API_ROUTES.DASHBOARD_SATES.URL,
    data,
  });
};
export const getDashboardClaimSates = (data) => {
  return apiClient({
    method: API_ROUTES.DASHBOARD_CLAIM_SATES.METHOD,
    url: API_ROUTES.DASHBOARD_CLAIM_SATES.URL,
    data,
  });
};
export const getDashboarConsentAndEligibilityList = (data) => {
  return apiClient({
    method: API_ROUTES.DASHBOARD_CONSENT_AND_ELIGIBILITY_DATA.METHOD,
    url: API_ROUTES.DASHBOARD_CONSENT_AND_ELIGIBILITY_DATA.URL,
    data,
  });
};
export const getDashboarConsentNoEligibilityList = (data) => {
  return apiClient({
    method: API_ROUTES.DASHBOARD_CONSENT_NO_ELIGIBILITY_DATA.METHOD,
    url: API_ROUTES.DASHBOARD_CONSENT_NO_ELIGIBILITY_DATA.URL,
    data,
  });
};
