import React, { useEffect, useState } from 'react';
import Button from '../../../../components/Button';
import { useAuth } from '../../../../hooks/useAuth';
import { LogoutIcon } from '../../../../assets/icons/useIcons';
import nextereLogo from '../../../../assets/images/nextere-logo.png';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAlert } from '../../../../hooks/useAlert';
import { verifyConsumer } from '../VerifySignup/actions';
import Verification from '../../../Verification';
import VerificationPremise from '../../../VerificationPremise';

const VerifyPage = () => {
  const { clearUser } = useAuth();
  let queryClient = useQueryClient();
  const { showAlert } = useAlert();
  // const [isVerified, setIsVerified] = useState(false);
  const [stepOne, setStepOne] = useState(false);
  const [stepTwo, setStepTwo] = useState(false);

  const { mutate, isLoading } = useMutation({
    mutationFn: verifyConsumer,
    mutationKey: 'verifyConsumer',
    onSuccess: ({ data }) => {
      showAlert({ type: 'success', message: data?.data?.message });
      queryClient.invalidateQueries({ queryKey: ['getUserDetails'] });
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'danger', message: errMsg });
    },
  });

  // useEffect(() => {
  //   const iframeElement = ref.current;
  //   console.log(iframeElement);
  //   if (iframeElement) {
  //     window.addEventListener('message', function (event) {
  //       console.log('🚀 ~ event:', event);
  //       if (event.source === iframeElement.contentWindow) {
  //         var response = event.data;
  //         console.log(
  //           'Received response data from iframe:',
  //           response,
  //           event.origin,
  //         );
  //         if (response?.respuesta) {
  //           setIsVerified(true);
  //         }
  //       }
  //     });
  //   }
  // }, [ref.current]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setIsProcessing(false);
  //   }, 2000);
  // }, []);

  // if (isLoading) {
  //   return (
  // <div className="d--flex align-items--center justify-content--center h--full w--full">
  //   <Spinner size="lg" />
  // </div>
  //   );
  // }

  const isVerified = stepOne === true && stepTwo === true;

  return (
    <div className="w--full h--full  d--flex  flex--column loginPage  overflow--y-auto verification-iframe">
      <div className="w--full h-min--60 d--flex justify-content--between align-items--center bg--white p-t--xs p-l--md p-r--md border-bottom--black-50">
        <img
          src={nextereLogo}
          alt="nextereLogo"
          className="w-max--150 logoConst"
        />
        <Button
          btnClasses="btn w-max--100  w--auto"
          size="md"
          variant="primary"
          color="black"
          onClick={() => {
            clearUser();
          }}
        >
          <span className="m-r--xs">
            <LogoutIcon />
          </span>
          <span>Logout</span>
        </Button>
      </div>
      <div className="d--flex align-items--center justify-content--center w--full h--full flex--column p--lg h--full bg--black-25">
        <div className="d--flex align-items--center justify-content--center flex--column gap--sm  w--full w-max--500  h--full   radius--sm p--xs">
          {/* <Verification setStepOne={setStepOne} setStepTwo={setStepTwo} /> */}
          <VerificationPremise
            setStepOne={setStepOne}
            setStepTwo={setStepTwo}
          />
          {/* 
            // <iframe
            //   title="iframhubhai"
            //   ref={ref}
            //   allow="camera *;https://conxent.nextere.com/"
            //   src="https://portal.onid.us/wa/auth?authmech=OnID%20Password&do=login&username=NHP001&password=H_9u2E.p?K4x})umM0P~&cbval_vendorBase64=AAAk%2FwMGAAAAAQ%3D%3D&Submit1=Yes&&location=https://webcomponentpsv.onid.us"
            // ></iframe> */}

          {isVerified ? (
            <Button
              btnClasses="btn skip-verify-btn text--white myAccountBtn"
              size="md"
              variant="danger"
              disabled={isLoading}
              onClick={() => {
                mutate();
              }}
            >
              <span className="m-r--xs"></span>
              {isLoading ? 'Please wait...' : 'My account'}
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default VerifyPage;
