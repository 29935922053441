import React from 'react';
import useSelfieCapture from '../../../hooks/useSelfieCapture';
import useIcons from '../../../assets/icons/useIcons';

const CameraCapture = ({
  onCameraCapture = () => null,
  onCameraCancel = () => null,
}) => {
  const { InfoIcon, CameraIcon, RefreshIcon, CheckIcon } = useIcons();
  const { cameraError, clearSelfie, selfie, takeSelfie, videoTag } =
    useSelfieCapture();
  return (
    <div className="w--full h-max--250 h--full d--flex flex--column align-items--center position--relative ">
      <div className="w--full h--full  position--absolute p--md h-min--260">
        {!selfie && (
          <div className="w--full h--full radius--md border-full--primary box-shadow--md border--dashed border--4 d--flex align-items--center"></div>
        )}
      </div>
      {selfie ? (
        <img
          className="w--full h--full object-fit--cover"
          src={selfie}
          alt="selfie"
        />
      ) : (
        <div className="h-min--260 w--full">
          {cameraError ? (
            <div className="h-min--250 ">{cameraError}</div>
          ) : (
            videoTag
          )}
        </div>
      )}

      {!selfie && (
        <div
          className="d--flex align-items--center gap--sm text--xs m-t--xs"
          style={{ fontStyle: 'italic', fontSize: 12 }}
        >
          <InfoIcon width={18} height={18} />
          <b>
            Please try to fit your document inside the frame and click camera
            button
          </b>
        </div>
      )}
      <div className="d--flex justify-content--center gap--lg m-t--sm">
        {selfie && (
          <div
            className="w-min--60 h-min--60 w-max--60 h-max--60 d--flex justify-content--center align-items--center bg--danger-100 border-full--danger-200 radius--full"
            onClick={clearSelfie}
          >
            <div className="w-min--50 h-min--50 w-max--50 h-max--50 d--flex justify-content--center align-items--center bg--danger-200 text--danger  radius--full c--pointer">
              <RefreshIcon />
            </div>
          </div>
        )}
        {!selfie && (
          <div
            className="w-min--60 h-min--60 w-max--60 h-max--60 d--flex justify-content--center align-items--center bg--black-25 border-full--black-200 radius--full"
            onClick={takeSelfie}
          >
            <div className="w-min--50 h-min--50 w-max--50 h-max--50 d--flex justify-content--center align-items--center bg--black-50  radius--full c--pointer">
              <CameraIcon />
            </div>
          </div>
        )}
        {selfie && (
          <div
            className="w-min--60 h-min--60 w-max--60 h-max--60 d--flex justify-content--center align-items--center bg--success-100 border-full--success-200 radius--full"
            onClick={() => onCameraCapture(selfie)}
          >
            <div className="w-min--50 h-min--50 w-max--50 h-max--50 d--flex justify-content--center align-items--center bg--success-200 text--success  radius--full c--pointer">
              <CheckIcon width={30} height={30} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CameraCapture;
