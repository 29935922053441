import React, { Fragment, useState } from 'react';
import Modal from '../Modal';
import Button from '../Button';

const ActionConfirmation = ({
  title = '',
  onConfirm = () => {
    return;
  },
  onCancel = () => {
    return;
  },
  children,
  message = '',
  extraClassess = '',
  btnText = 'No',
  confirmBtnText = 'Yes',
}) => {
  const [show, setShow] = useState(false);
  return (
    <>
      <span className={`${extraClassess}`} onClick={() => setShow(true)}>
        {children}
      </span>
      {show && (
        <Modal
          handleClose={() => setShow(false)}
          title={title}
          headerComponent={null}
          shouldCloseOnClickOutside={true}
          footerComponent={
            <div className="d--flex gap--sm justify-content--center w--full p-t--md">
              <Button
                type="button"
                variant="transparent"
                color="black"
                isOutline
                borderColor="primary"
                data-link-hover
                btnClasses="btn w-max--150 w-min--150"
                onClick={() => {
                  onCancel && onCancel();
                  setShow(false);
                }}
              >
                {btnText}
              </Button>
              <Button
                type="button"
                variant="primary"
                color="black"
                btnClasses="btn w-max--150 w-min--150"
                onClick={() => {
                  onConfirm && onConfirm();
                  setShow();
                }}
              >
                {confirmBtnText}
              </Button>
            </div>
          }
          size="500"
          height="250"
        >
          <div
            className="font--md m-b--0 text--black-600 line-height--1-dot-5 "
            style={{ whiteSpace: 'normal' }}
          >
            {message}
          </div>
        </Modal>
      )}
    </>
  );
};

export default ActionConfirmation;
