import React from 'react';

import { Outlet, useLocation, useNavigate } from 'react-router-dom';

const AgencyAgentConfiguration = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <>
      <div className="d--flex gap--lg profilePageTabe  border-bottom--black-100 m-b--md">
        <span
          className={`p-l--md p-r--md p-b--md c--pointer font--sm font--600   ${
            pathname.split('/').pop() === 'email-integration'
              ? 'border-bottom--black text--black'
              : 'text--black-600'
          }`}
          onClick={() => {
            navigate('/configuration/email-integration');
          }}
        >
          Email Integration
        </span>
        <span
          className={`p-l--md p-r--md p-b--md c--pointer font--sm font--600   ${
            pathname.split('/').pop() === 'storage'
              ? 'border-bottom--black text--black'
              : 'text--black-600'
          }`}
          onClick={() => {
            navigate('/configuration/storage');
          }}
        >
          Storage
        </span>
        <span
          className={`p-l--md p-r--md p-b--md c--pointer font--sm font--600 white-space--nowrap    ${
            pathname.split('/').pop() === 'consentWebhook' &&
            'border-bottom--black text--black'
          }`}
          onClick={() => {
            navigate('/configuration/consentWebhook');
          }}
        >
          Consent Create Webhook
        </span>
      </div>
      <div className="d--flex gap--md flex--column profilePage  bg--white radius--sm ">
        {/* {activeTabLookUp?.[activeTab]} */}
        <Outlet />
      </div>
    </>
  );
};

export default AgencyAgentConfiguration;
