import React from 'react';
import LogoX from '../../../assets/images/XlogoWhite.png';
import nextereLogoLong from '../../../assets/images/nextere-logo.png';
import Button from '../../../components/Button';

const OuterHeader = () => {
  return (
    <div className="outerHeader w--full box--shadow z-index--sm">
      <div className="h-min--64  d--flex align-items--center gap--md">
        <div className="w--full d--flex align-items--center ">
          <div className="h--full w-min--64 h-min--64 d--flex align-items--center justify-content--center bg--primary p-t--xs c--pointer smallLogoSec">
            <img
              src={LogoX}
              alt="logoX"
              width={24}
              height={35}
              className="m-t--sm"
            />
          </div>
          <div className="d--flex align-items--center gap--lg p-l--sm p-b--sm navigatSec">
            <img
              src={nextereLogoLong}
              alt="logoX"
              width={136}
              height={32}
              className="m-t--sm m-l--xs"
            />
            <div className="h-min--45 h-max--45 d--flex w-min--1 w-max--1 bg--black-100 m-t--sm m-l--xs d-none--xs"></div>
            <div className="d--flex gap--lg  navigat ">
              <div
                className="font--md c--pointer text--black navigatItem"
                style={{ marginLeft: '2px' }}
              >
                Why ConXent
              </div>
              <div className="font--md c--pointer text--black navigatItem">
                Features
              </div>
              <div className="font--md c--pointer text--black navigatItem">
                Plans
              </div>
            </div>
          </div>
        </div>
        <div className="w--full d--flex gap--md w-max--400 justify-content--end p-r--3xl">
          <Button
            variant="transparent"
            color="black"
            isOutline
            borderColor="black"
            btnClasses="btn w-max--180"
            // data-link-hover-black-200
          >
            Register for a Webinar
          </Button>
          <Button variant="primary" color="black" btnClasses="btn w-max--100">
            Login
          </Button>
        </div>
      </div>
    </div>
  );
};

export default OuterHeader;
