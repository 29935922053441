import { Outlet } from 'react-router-dom';

const DocumentLayout = () => {
  return (
    <div
      className="d--flex gap--sm w--full h--full"
      style={{ background: '#f1f1f1' }}
    >
      <Outlet />
    </div>
  );
};

export default DocumentLayout;
