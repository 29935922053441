import React from 'react';

const FormSelect = (
  {
    placeholder = '',
    label,
    error,
    extraClasses = '',
    options = [],
    renderOption: RenderOption,
    height = '40',
    paddingLeft = 'md',
    paddingRight = 'md',
    radiusLeft = 'sm',
    radiusRight = 'sm',
    required = true,
    ...rest
  },
  ref,
) => {
  const text = error || label || '';
  return (
    <div className="w--full d--flex flex--column position--relative">
      {text && (
        <label
          className={`label--control font--sm font--500 m-b--sm   ${
            error ? 'text--danger' : 'text--grey'
          }`}
        >
          {text}
          {required && (
            <span className=" font--sm text--danger m-l--xs">*</span>
          )}
        </label>
        // <label
        //   className={`label--control font--2sm font--500  position--absolute top---8  left--15 bg--white  ${
        //     error ? 'text--danger' : 'text--grey'
        //   }`}
        // >
        //   {text}
        //   {required && (
        //     <span className=" font--sm text--danger m-l--xs">*</span>
        //   )}
        // </label>
      )}

      <select
        {...rest}
        ref={ref}
        placeholder={placeholder}
        className={`form--control w--full h-min--${height} r-t-left--${radiusLeft} r-t-right--${radiusRight} r-b-left--${radiusLeft} 
        r-b-right--${radiusRight} p-l--${paddingLeft} p-r--${paddingRight} ${extraClasses} 
        ${error ? 'border-full--danger' : 'border-full--black-200'}`}
      >
        {placeholder && <option value="">{placeholder}</option>}
        {options &&
          options.length > 0 &&
          options.map((item) => (
            <option key={item.value} value={item.value}>
              {item.label}
            </option>
          ))}
      </select>
    </div>
  );
};

export default React.forwardRef(FormSelect);
