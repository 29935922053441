import { CARRIER } from '../../helpers/constants';
import { useAuth } from '../../hooks/useAuth';
import AgencyAgentConfiguration from './View/AgencyAgentConfiguration';
import CarrierConfiguration from './View/CarrierConfiguration';

export default function Configuration() {
  const { user } = useAuth();
  const userRole = user?.role || localStorage.getItem('nph-agency-user-role');
  if (userRole === CARRIER) {
    return <CarrierConfiguration />;
  } else {
    return <AgencyAgentConfiguration />;
  }
}
