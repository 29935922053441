import React from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

const CarrierConfiguration = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <>
      <div className="d--flex gap--lg profilePageTabe  border-bottom--black-100 m-b--md overflow--auto">
        <span
          className={`p-l--md p-r--md p-b--md c--pointer font--sm font--600 white-space--nowrap  ${
            pathname.split('/').pop() === 'claim-webhook'
              ? 'border-bottom--black text--black'
              : 'text--black-600'
          }`}
          onClick={() => {
            navigate('/configuration/claim-webhook');
          }}
        >
          Claim Webhook
        </span>
        <span
          className={`p-l--md p-r--md p-b--md c--pointer font--sm font--600 white-space--nowrap    ${
            pathname.split('/').pop() === 'consent-webhook' &&
            'border-bottom--black text--black'
          }`}
          onClick={() => {
            navigate('/configuration/consent-webhook');
          }}
        >
          Consent Webhook
        </span>
        <span
          className={`p-l--md p-r--md p-b--md c--pointer font--sm font--600  white-space--nowrap   ${
            pathname.split('/').pop() === 'consent-check' &&
            'border-bottom--black text--black'
          }`}
          onClick={() => {
            navigate('/configuration/consent-check');
          }}
        >
          Consent Check
        </span>
      </div>
      <div className="d--flex gap--md flex--column profilePage  bg--white radius--sm ">
        <Outlet />
      </div>
    </>
  );
};

export default CarrierConfiguration;
