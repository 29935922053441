import * as yup from 'yup';
import {
  email,
  firstName,
  lastName,
  name,
  npn,
  phone,
  postalCode,
  requiredFieldFunction,
} from '../../../../helpers/yupValidations';

export const initialValues = {
  step_one: {
    npn: '',
    ssn: '',
    firstName: '',
    lastName: '',
  },
  step_two: {
    isAssociatedAgency: 'N',
    associatedAgencyName: '',
    associatedAgencyEmail: '',
    associatedAgencyPhone: '',
    associatedAgencyNPN: '',
    associatedAddressOne: '',
    associatedAddressTwo: '',
    associatedState: '',
    associatedCity: '',
    associatedZipCode: '',
  },
  step_three: {
    isOwnAgency: 'N',
    ownAgencyType: 'REGULAR',
    ownAgencyName: '',
    ownAgencyEmail: '',
    ownAgencyPhone: '',
    ownAgencyNPN: '',
    ownAgencyEIN: '',
    ownAddressOne: '',
    ownAddressTwo: '',
    ownState: '',
    ownCity: '',
    ownZipCode: '',
  },
};

// Agent Signup Schema
export const stepOneSchema = yup.object({
  step_one: yup.object({
    firstName,
    lastName,
    npn,
    ssn: yup
      // .number()
      .string()
      .required('SSN is required')
      .typeError('SSN is required')
      // .positive('Must be a positive number')
      .test('len', 'Must be between 5 and 12 digits', (val) => {
        const length = val ? val.toString().length : 0;
        return length >= 5 && length <= 12;
      }),
  }),
});

export const stepTwoSchema = yup.object({
  step_two: yup.object({
    isAssociatedAgency: yup.string(),
    associatedAgencyName: yup.string().when('isAssociatedAgency ', {
      is: (val) => val === 'Y',
      then: () => name,
      otherwise: () => yup.string(),
    }),
    associatedAgencyEmail: yup.string().when('isAssociatedAgency ', {
      is: (val) => val === 'Y',
      then: () => email,
      otherwise: () => yup.string(),
    }),
    associatedAgencyPhone: yup.string().when('isAssociatedAgency ', {
      is: (val) => val === 'Y',
      then: () => phone,
      otherwise: () => yup.string(),
    }),
    // associatedAgencyNPN: yup.string().when('isAssociatedAgency ', {
    //   is: (val) => val === 'Y',
    //   then: () => npn,
    //   otherwise: () => yup.string(),
    // }),
    associatedAddressOne: yup.string().when('isAssociatedAgency ', {
      is: (val) => val === 'Y',
      then: () => requiredFieldFunction('Agency address'),
      otherwise: () => yup.string(),
    }),
    associatedState: yup.string().when('isAssociatedAgency ', {
      is: (val) => val === 'Y',
      then: () => requiredFieldFunction('Agency state'),
      otherwise: () => yup.string(),
    }),
    associatedCity: yup.string().when('isAssociatedAgency ', {
      is: (val) => val === 'Y',
      then: () => requiredFieldFunction('Agency city'),
      otherwise: () => yup.string(),
    }),
    associatedZipCode: yup.string().when('isAssociatedAgency ', {
      is: (val) => val === 'Y',
      then: () => postalCode,
      otherwise: () => yup.string(),
    }),
  }),
});

export const stepThreeSchema = yup.object({
  step_three: yup.object({
    isOwnAgency: requiredFieldFunction('own agency'),
    ownAgencyType: yup.string(),
    ownAgencyName: yup.string().when('isOwnAgency', {
      is: (val) => val === 'Y',
      then: () => name,
      otherwise: () => yup.string(),
    }),
    ownAgencyEmail: yup.string().when('isOwnAgency', {
      is: (val) => val === 'Y',
      then: () => email,
      otherwise: () => yup.string(),
    }),
    ownAgencyPhone: yup.string().when('isOwnAgency', {
      is: (val) => val === 'Y',
      then: () => phone,
      otherwise: () => yup.string(),
    }),
    ownAgencyNPN: yup.string().when('ownAgencyType', {
      is: (val) => val === 'FMO',
      then: () => npn,
      otherwise: () => yup.string(),
    }),
    ownAgencyEIN: yup.string().when('isOwnAgency', {
      is: (val) => val === 'Y',
      then: () => requiredFieldFunction('Agency ein'),
      otherwise: () => yup.string(),
    }),
    ownAddressOne: yup.string().when('isOwnAgency', {
      is: (val) => val === 'Y',
      then: () => requiredFieldFunction('Agency address'),
      otherwise: () => yup.string(),
    }),
    ownState: yup.string().when('isOwnAgency', {
      is: (val) => val === 'Y',
      then: () => requiredFieldFunction('Agency state'),
      otherwise: () => yup.string(),
    }),
    ownCity: yup.string().when('isOwnAgency', {
      is: (val) => val === 'Y',
      then: () => requiredFieldFunction('Agency city'),
      otherwise: () => yup.string(),
    }),
    ownZipCode: yup.string().when('isOwnAgency', {
      is: (val) => val === 'Y',
      then: () => postalCode,
      otherwise: () => yup.string(),
    }),
  }),
});

export const API_ROUTES = {
  VERIFY_USER: {
    METHOD: 'POST',
    URL: 'users/verify',
  },
  VERIFY_CONSUMER: {
    METHOD: 'POST',
    URL: 'consumer/verify',
  },
  USER_SIGNUP: {
    METHOD: 'POST',
    URL: 'users/create',
  },
};
