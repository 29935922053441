import {
  requiredFieldFunction,
  requiredFieldText,
} from '../../helpers/yupValidations';
import * as yup from 'yup';

export const initialModalState = {
  modalType: '',
  isShow: false,
  elementData: {},
  type: '',
};

export const API_ROUTES = {
  GET_MEMBER_LIST: {
    METHOD: 'POST',
    URL: 'member/list',
  },
  GET_MEMBER_ENROLLMENT_LIST: {
    METHOD: 'POST',
    URL: 'member/enrollment-list',
  },
  CREATE_MEMBER: {
    METHOD: 'POST',
    URL: 'member/create',
  },
  IMPORT_MEMBER: {
    METHOD: 'POST',
    URL: 'member/import-consumer',
  },
  UPDATE_MEMBER: {
    METHOD: 'POST',
    URL: 'member/update',
  },
  DELETE_MEMBER: {
    METHOD: 'POST',
    URL: 'member/delete',
  },
  UPDATE_MEMBER_STATUS: {
    METHOD: 'POST',
    URL: 'member/update-status',
  },
  SEND_INVITE: {
    METHOD: 'POST',
    URL: 'agreement/send-invitation',
  },
  AGENCY_LIST_DROPDOWN: {
    METHOD: 'GET',
    URL: 'agency/list',
  },
  UPLOAD_CONSENT_DOC: {
    METHOD: 'POST',
    URL: 'agent/upload-doc',
  },
  UPLOAD_ELIGIBILITY_DOC: {
    METHOD: 'POST',
    URL: 'member/upload-eligibility-doc',
  },
};

export const addMemberInitialValues = {
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
  enrollmentYear: '2024',
  policyId: '',
  // memberType: '',
  // InsuranceProvider: '',
  // InsuranceProviderPlan: '',
};
export const yearsList = [
  {
    label: '2024',
    value: '2024',
  },
  {
    label: '2025',
    value: '2025',
  },
  {
    label: '2026',
    value: '2026',
  },
  {
    label: '2027',
    value: '2027',
  },
  {
    label: '2028',
    value: '2028',
  },
  {
    label: '2029',
    value: '2029',
  },
  {
    label: '2030',
    value: '2030',
  },
];

export const memberTypeDropdown = [
  {
    label: 'NON MEMBER',
    value: 'NON_MEMBER',
  },
  {
    label: 'ACA MEMBER',
    value: 'ACA_MEMBER',
  },
  {
    label: 'MEDICARE MEMBER',
    value: 'MEDICARE_MEMBER',
  },
  {
    label: 'LIFE INSURANCE MEMBER',
    value: 'LIFE_INSURANCE_MEMBER',
  },
  {
    label: 'DENTAL MEMBER',
    value: 'DENTAL_MEMBER',
  },
];

// Add Member
export const schema = yup.object({
  firstName: requiredFieldFunction('First name'),
  lastName: requiredFieldFunction('Last name'),
  phone: requiredFieldFunction('Phone'),
  enrollmentYear: requiredFieldFunction('Enrollment Year'),
  email: yup
    .string()
    .required(requiredFieldText('Email'))
    .email('Invalid email format')
    .max(50, 'Max length 50 characters long'),
  // memberType: requiredFieldFunction('Member type'),
  // InsuranceProvider: requiredFieldFunction('Insurance Provider'),
  // InsuranceProviderPlan: requiredFieldFunction('Insurance Provider Plan'),
});

export const filterDefaultValues = [
  {
    id: 1,
    type: 'date',
    label: 'Consent Date',
    key: 'submittedDate',
  },
  {
    id: 2,
    type: 'date',
    label: 'Eligibility Date',
    key: 'eligibilitySubmittedDate',
  },
  {
    id: 3,
    type: 'email',
    label: 'Email',
    key: 'email',
  },
];
