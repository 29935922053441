export const API_ROUTES = {
  DASHBOARD_SATES: {
    METHOD: 'GET',
    URL: 'dashboard/sates',
  },
  DASHBOARD_CLAIM_SATES: {
    METHOD: 'GET',
    URL: 'dashboard/claim-sates',
  },
  DASHBOARD_CONSENT_AND_ELIGIBILITY_DATA: {
    METHOD: 'POST',
    URL: 'dashboard/member-consent-and-eligibility-sates',
  },
  DASHBOARD_CONSENT_NO_ELIGIBILITY_DATA: {
    METHOD: 'POST',
    URL: 'dashboard/member-no-eligibility-sates',
  },
};
