import React, { useState, useContext, createContext, useMemo } from 'react';
const AuthContext = createContext(null);

export const Auth = ({ children }) => {
  const [user, setCurrentUser] = useState(null);
  const [agencyData, setAgencyData] = useState(null);

  const setUser = (data) => {
    localStorage.setItem('nph-agency-user-role', data?.role);
    localStorage.setItem('nph-agency-acc-tkn', data?.token);
  };

  const setUserData = (payload) => {
    setCurrentUser({ ...payload });
  };

  const clearUser = () => {
    localStorage.removeItem('nph-agency-user-role');
    localStorage.removeItem('nph-agency-acc-tkn');

    // localStorage.clear();
    window.location.href = '/';
    setCurrentUser(null);
  };

  const values = useMemo(
    () => ({
      user,
      setUser,
      clearUser,
      setUserData,
      agencyData,
      setAgencyData,
    }),
    [user],
  );

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
