import React, { useState } from 'react';
import SelfieCapture from './SelfieCapture';

const SelfieVerification = ({ setDocumentData = () => null }) => {
  const [document, setDocument] = useState(null);
  return (
    <>
      <div className="w--full verification-header d--flex align-items--center justify-content--center p-t--md p-l--sm p-r--sm m-b--sm">
        <h3 className="font--lg font--600">Take Selfie</h3>
      </div>
   

      <SelfieCapture
        onCameraCapture={(data) => {
          setDocument(data);
          setDocumentData(data);
        }}
      />
      </>
  );
};

export default SelfieVerification;
