import React, { lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom';

// constants
import { AGENCY_USER, AGENT, CARRIER, MEMBER } from '../helpers/constants';

// Error pages
import Error500Page from '../pages/Error500Page';
import PageNotFound from '../pages/PageNotFound';
import Unauthorized from '../pages/Unauthorized';

// public routes
import Login from '../pages/Login';
import ForgotPassword from '../pages/ForgotPassword';

// protected routes
import Dashboard from '../pages/Dashboard';
import Members from '../pages/Members';
import Agents from '../pages/Agents';
import Agency from '../pages/Agency';
import Settings from '../pages/Settings';
import MyAccount from '../pages/MyAccount';

// auth related components
import Access from '../auth/Access';

import CreateTemplate from '../pages/CreateTemplate';
import ViewSignedPDF from '../pages/ViewSignedPDF';
import Claims from '../pages/Claims';
import Invitation from '../pages/Invitation';
import RecieveInvitation from '../pages/RecieveInvitation';
import { ChatPage } from '../pages/Chat';
import SignUp from '../pages/SignUp';
import ChatArea from '../pages/Chat/ChatArea';
import Verify from '../pages/Verify';
import { EligibilityPdf } from '../pages/Pdf/EligibilityPdf';
import DocumentLayout from '../layouts/DocumentLayout';
import PreviewDocument from '../components/PDFForms/PreviewDocument';
import PreviewDocumentReadMode from '../components/PDFForms/PreviewDocumentReadMode';
import PreviewDocumentSignMode from '../components/PDFForms/PreviewDocumentSignMode';
import AgentKpi from '../pages/AgentKpi';
import Verification from '../pages/Verification';
import PreviewDocumentSignReadMode from '../components/PDFForms/PreviewDocumentSignReadMode';
import Configuration from '../pages/Configuration';
import LoginOtp from '../pages/Login/LoginOtp';
import EmailIntegration from '../pages/MyAccount/Components/EmailIntegration';
import Webhook from '../pages/MyAccount/Components/Webhook';
import InboundWebhook from '../pages/MyAccount/Components/InboundWebhook';
import Conxentkey from '../pages/MyAccount/Components/Conxentkey';
import Storage from '../pages/Storage';
import ConsentWebhook from '../pages/Configuration/Components/ConsentWebhook';
import PlanPricing from '../pages/PlanPricing';

// lazy loading
const AuthRemover = lazy(() => import('../auth/AuthRemover'));
const OuterLayout = lazy(() => import('../layouts/OuterLayout'));
const AuthProvider = lazy(() => import('../auth/AuthProvider'));
const AuthLayout = lazy(() => import('../layouts/AuthLayout'));
const VerifyProvider = lazy(() => import('../auth/VerifyProvider'));

export const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <AuthProvider>
        <AuthLayout />
      </AuthProvider>
    ),
    errorElement: <Error500Page />,
    children: [
      {
        path: 'dashboard',
        element: (
          <Access allowedRoles={[AGENT, AGENCY_USER, CARRIER]}>
            <Dashboard />
          </Access>
        ),
        id: 'Dashboard',
      },
      {
        path: 'agency',
        element: (
          <Access allowedRoles={[AGENT]}>
            <Agency />
          </Access>
        ),
        id: 'Agency',
      },
      {
        path: 'agents',
        element: <Agents />,
        id: 'Agent',
      },

      {
        path: 'consumer',
        element: <Members />,
        id: 'Consumer',
      },
      // {
      //   path: 'documents',
      //   element: <Templates />,
      //   id: 'Documents',
      // },

      {
        path: 'settings',
        element: <Settings />,
        id: 'Settings',
      },
      {
        path: 'my-account',
        element: <MyAccount />,
        id: 'MyAccount',
      },
      {
        path: 'create-template',
        element: <CreateTemplate />,
        id: 'create-template',
      },
      // {
      //   path: 'email-integration',
      //   element: (
      //     <Access allowedRoles={[AGENT, AGENCY_USER]}>
      //       <EmailIntegration />
      //     </Access>
      //   ),
      //   id: 'email-integration',
      // },
      {
        path: 'configuration',
        element: (
          <Access allowedRoles={[AGENT, AGENCY_USER, CARRIER]}>
            <Configuration />
          </Access>
        ),
        children: [
          {
            path: 'email-integration',
            element: (
              <Access allowedRoles={[AGENT, AGENCY_USER]}>
                <EmailIntegration />
              </Access>
            ),
          },
          {
            path: 'storage',
            element: (
              <Access allowedRoles={[AGENT, AGENCY_USER]}>
                <Storage />
              </Access>
            ),
          },
          {
            path: 'consentWebhook',
            element: (
              <Access allowedRoles={[AGENT, AGENCY_USER]}>
                <ConsentWebhook />
              </Access>
            ),
          },
          {
            path: 'claim-webhook',
            element: (
              <Access allowedRoles={[CARRIER]}>
                <Webhook />
              </Access>
            ),
          },
          {
            path: 'consent-webhook',
            element: (
              <Access allowedRoles={[CARRIER]}>
                <InboundWebhook />
              </Access>
            ),
          },
          {
            path: 'consent-check',
            element: (
              <Access allowedRoles={[CARRIER]}>
                <Conxentkey />
              </Access>
            ),
          },
        ],
        id: 'configuration',
      },
      // {
      //   path: 'view-template',
      //   element: <PdfViewerComponent />,
      //   id: 'view-template',
      // },

      {
        path: 'view-signed-pdf',
        element: <ViewSignedPDF />,
        id: 'view-signed-pdf',
      },
      {
        path: 'claims',
        element: (
          <Access allowedRoles={[AGENT, AGENCY_USER, CARRIER]}>
            <Claims />
          </Access>
        ),
        id: 'claims',
      },
      {
        path: 'invites',
        element: (
          <Access allowedRoles={[AGENT, AGENCY_USER]}>
            <Invitation />
          </Access>
        ),
        id: 'Invites',
      },
      {
        path: 'chat',
        element: (
          <Access allowedRoles={[AGENT, AGENCY_USER, CARRIER, MEMBER]}>
            <ChatPage />
          </Access>
        ),
        children: [{ path: ':chatId', element: <ChatArea /> }],
        id: 'chat',
      },
      // {
      //   path: 'storage',
      //   element: (
      //     <Access allowedRoles={[AGENT, AGENCY_USER, CARRIER]}>
      //       <Storage />
      //     </Access>
      //   ),
      //   id: 'storage',
      // },
      {
        path: 'agent-kpis',
        element: (
          <Access allowedRoles={[AGENCY_USER]}>
            <AgentKpi />
          </Access>
        ),
        id: 'agentKPIs',
      },
      {
        path: 'recieve-invitation',
        element: (
          <Access allowedRoles={[AGENT]}>
            <RecieveInvitation />
          </Access>
        ),
        id: 'recieve-invitation',
      },
      // {
      //   path: 'signature-doc',
      //   element: (
      //     <PSPdfContextProvider>
      //       <SignPDF />
      //     </PSPdfContextProvider>
      //   ),
      //   id: 'signature-doc',
      // },
    ],
  },
  {
    path: '/',
    element: (
      <AuthRemover>
        <OuterLayout />
      </AuthRemover>
    ),
    errorElement: <Error500Page />,
    children: [
      {
        index: true,
        element: <Login />,
        id: 'Login',
      },
      {
        path: 'login-otp',
        element: <LoginOtp />,
        id: 'login-otp',
      },
      {
        path: 'sign-up',
        element: <SignUp />,
        id: 'SignUp',
      },
      {
        path: 'forgot-password',
        element: <ForgotPassword />,
        id: 'forgot-password',
      },
      {
        path: 'plan-pricing',
        element: <PlanPricing />,
        id: 'PlanPricing',
      },
      // {
      //   path: 'reset-password',
      //   element: <ResetPassword />,
      //   id: 'reset-password',
      // },
    ],
  },

  {
    path: 'verify',
    element: <VerifyProvider />,
    children: [
      {
        index: true,
        element: <Verify />,
        errorElement: <Error500Page />,
      },
    ],
  },
  {
    path: '/unauthorized',
    element: <Unauthorized />,
  },
  // {
  //   path: 'signature/:id',
  //   element: <PdfFormFill />,
  // },
  // {
  //   path: 'signature-doc',
  //   element: (
  //     <PSPdfContextProvider>
  //       <SignPDF />
  //     </PSPdfContextProvider>
  //   ),
  //   id: 'signature-doc',
  // },
  // {
  //   path: 'verify-pdf',
  //   element: (
  //     <PSPdfContextProvider>
  //       <VerifyPDF />
  //     </PSPdfContextProvider>
  //   ),
  //   id: 'verify-pdf',
  // },
  {
    path: 'view-pdf',
    element: <EligibilityPdf />,
    id: 'view-pdf',
  },
  {
    path: 'verify-identity',
    element: <Verification />,
    id: 'verify-identity',
  },
  {
    path: 'view-document',
    element: <DocumentLayout />,
    id: 'view-document',
    children: [
      { index: true, element: <PreviewDocument /> },
      { path: ':docId', element: <PreviewDocument /> },
    ],
  },
  {
    path: 'read-document',
    element: <DocumentLayout />,
    id: 'read-document',
    children: [
      { index: true, element: <PreviewDocumentReadMode /> },
      { path: ':name', element: <PreviewDocumentReadMode /> },
    ],
  },
  {
    path: 'signature-document',
    element: <DocumentLayout />,
    id: 'signature-document',
    children: [
      { index: true, element: <PreviewDocumentSignMode /> },
      { path: ':docId', element: <PreviewDocumentSignMode /> },
    ],
  },
  {
    path: 'view-signature-document',
    element: <DocumentLayout />,
    id: 'view-signature-document',
    children: [
      { index: true, element: <PreviewDocumentSignReadMode /> },
      { path: ':docId', element: <PreviewDocumentSignReadMode /> },
    ],
  },
  {
    path: '*',
    element: <PageNotFound />,
  },
]);
