import React, { useEffect, useState } from 'react';
import DocumentVerification from './DocumentVerification';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { documentVerification } from '../../api';
import Spinner from '../../components/Spinner';
import SelfieVerification from './SelfieVerification';
import useIcons from '../../assets/icons/useIcons';
import { verifyConsumer } from '../Verify/View/VerifySignup/actions';
import { useAlert } from '../../hooks/useAlert';

const VerificationPremise = () => {
  let queryClient = useQueryClient();
  const { showAlert } = useAlert();

  const { CrossIcon, CheckIcon } = useIcons();
  const { mutateAsync: documentVerifyMutate, isLoading } = useMutation({
    mutationKey: ['documentVerification'],
    mutationFn: documentVerification,
  });
  const [verificationDocuments, setVerificationDocuments] = useState({
    document: null,
    selfie: null,
  });
  const [verificationStatus, setIsVerificationStatus] = useState({
    isDocumentVerified: false,
    isSelfieVerified: false,
  });
  const [verificationData, setVerificationData] = useState(null);

  const {
    mutate,
    isLoading: isVerifying,
    isSuccess,
  } = useMutation({
    mutationFn: verifyConsumer,
    mutationKey: 'verifyConsumer',
    onSuccess: ({ data }) => {
      showAlert({ type: 'success', message: data?.data?.message });
      window.location.reload();
      // queryClient.invalidateQueries({ queryKey: ['getUserDetails'] });
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'danger', message: errMsg });
      window.location.reload();
    },
  });

  async function handleStepOne() {
    const response = await documentVerifyMutate({
      processParam: {
        authParams: { checkLiveness: false },
        scenario: 'FullAuth',
        useFaceApi: true,
      },
      List: [
        {
          ImageData: {
            image: verificationDocuments?.document,
          },
        },
      ],
    });

    if (response?.status === 200) {
      setIsVerificationStatus((prev) => ({
        ...prev,
        isDocumentVerified: true,
      }));
    }
  }
  async function handleStepTwo() {
    const response = await documentVerifyMutate({
      processParam: {
        authParams: { checkLiveness: false },
        scenario: 'FullAuth',
        useFaceApi: true,
      },
      extPortrait: verificationDocuments?.selfie,
      List: [
        {
          ImageData: {
            image: verificationDocuments?.document,
          },
        },
      ],
    });

    if (response?.status === 200) {
      try {
        const status =
          response?.data?.ContainerList?.List?.filter(
            (item) => item?.Status,
          )?.[0]?.Status?.portrait || 0;
        const images =
          response?.data?.ContainerList?.List?.filter(
            (item) => item?.Images,
          )?.[0]?.Images?.fieldList || [];

        const documentImages = await filterOutDocuments(images);

        if (documentImages) {
          setVerificationData({
            isVerified: status,
            documentImages: documentImages,
            ...response?.data,
          });
          setIsVerificationStatus((prev) => ({
            ...prev,
            isSelfieVerified: true,
          }));
        }
      } catch (e) {
        console.log(e);
      }
    }
  }

  useEffect(() => {
    if (verificationDocuments?.document && !verificationDocuments?.selfie) {
      handleStepOne();
    }

    if (verificationDocuments?.document && verificationDocuments?.selfie) {
      handleStepTwo();
    }
  }, [verificationDocuments?.document, verificationDocuments?.selfie]);

  async function filterOutDocuments(images = []) {
    return new Promise((resolve, reject) => {
      const documentImages = [];
      for (let index = 0; index < images.length; index++) {
        const item = images[index];
        if (!item?.valueList) continue;
        for (let index = 0; index < item?.valueList.length; index++) {
          const _ = item?.valueList[index];
          const val = {
            label: item?.fieldName,
            subLabel: _?.source,
            values: _?.value,
          };
          documentImages.push(val);
        }
      }
      resolve(documentImages);
    });
  }

  return (
    <div className="w--full h--full h-min--300 ">
      {isLoading || isVerifying ? (
        <div className="d--flex align-items--center justify-content--center h--full w--full">
          <Spinner size="lg" />
        </div>
      ) : (
        <>
          {isSuccess ? (
            'Please wait...'
          ) : (
            <>
              {!verificationStatus.isDocumentVerified ? (
                <DocumentVerification
                  setDocumentData={(data) => {
                    setVerificationDocuments((prev) => ({
                      ...prev,
                      document: data,
                    }));
                  }}
                />
              ) : !verificationStatus.isSelfieVerified ? (
                <SelfieVerification
                  setDocumentData={(data) => {
                    setVerificationDocuments((prev) => ({
                      ...prev,
                      selfie: data,
                    }));
                  }}
                />
              ) : (
                <div className="w--full d--flex">
                  {verificationData?.isVerified === 0 && (
                    <div className="border-full--black-100 w-max--600 w--full bg--white radius--md p--md gap--md d--flex flex--column align-items--center  h--full justify-content--between p--lg">
                      <div className="d--flex w--full gap--sm border-bottom--black-200 h-min--40 align-items--center p-b--md">
                        <div className="w-min--32 h-min--32 w-max--32 h-max--32 radius--full d--flex align-items--center justify-content--center bg--danger text--white">
                          <CrossIcon width={16} height={16} />
                        </div>
                        <div className="font--md font--600 text--black">
                          You are not verified
                        </div>
                      </div>
                      <div className="w--full w-max--600 d--flex gap--md align-items--start ">
                        {verificationData &&
                          verificationData?.documentImages &&
                          verificationData?.documentImages?.length > 0 &&
                          verificationData?.documentImages?.map((_) => {
                            return (
                              <div className="d--flex flex--column w--full gap--sm flex--column-xs">
                                <div className="font--xs font--500">
                                  {_?.label}
                                </div>
                                <div className="font--sm font--600">
                                  {_?.subLabel}
                                </div>
                                <div className="w--full d--flex justify-content--center">
                                  <img
                                    className="h--full "
                                    src={`data:image/png;base64,${_?.values}`}
                                  />
                                </div>
                              </div>
                            );
                          })}
                      </div>
                      <div
                        onClick={() => window.location.reload()}
                        className="w--full gap--sm border-full--danger bg--danger text--white  radius--full h-min--50 w-max--200 d--flex align-items--center justify-content--center font--500 font--lg c--pointer"
                      >
                        Try Again
                      </div>
                    </div>
                  )}
                  {verificationData?.isVerified === 1 && (
                    <div className="w--full p--md w-max--600 h-min--300 d--flex  flex--column  bg--white radius--md border-full--black-100 m-b--70-xs">
                      <div className="d--flex gap--sm border-bottom--black-200 h-min--40 align-items--center p-b--md">
                        <div className="w-min--32 h-min--32 w-max--32 h-max--32 radius--full d--flex align-items--center justify-content--center bg--success text--white">
                          {/* <CrossIcon width={16} height={16}  /> */}
                          <CheckIcon width={30} height={30} />
                        </div>
                        <div className="font--md font--600 text--black">
                          Portrait Comparison
                        </div>
                      </div>
                      <div className="d--flex gap--md">
                        <div className="w--full d--flex gap--md m-t--md flex--column-xs">
                          {verificationData &&
                            verificationData?.documentImages &&
                            verificationData?.documentImages?.length > 0 &&
                            verificationData?.documentImages?.map((_) => {
                              return (
                                <div className="d--flex flex--column w--full gap--sm">
                                  <div className="font--sm font--500">
                                    {_?.label}
                                  </div>
                                  <div className="font--xs font--600">
                                    {_?.subLabel}
                                  </div>
                                  <div className="w--full d--flex justify-content--center">
                                    <img
                                      className="h--full "
                                      src={`data:image/png;base64,${_?.values}`}
                                    />
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                      <div className="d--flex justify-content--center w--full m-t--lg position--fixed bottom--0 align-items--center left--0 bg--white p--sm ">
                        <div
                          onClick={() => mutate()}
                          className="w--full gap--sm border-full--success bg--success text--white  radius--full h-min--50 w-max--200 d--flex align-items--center justify-content--center font--500 font--lg c--pointer"
                        >
                          Continue
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default VerificationPremise;
