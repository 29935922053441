import { useState } from 'react';
import ShowNpnInfo from '../../../Claims/View/ShowNpnInfo';
import { CopyIcon, TickCircleIcon } from '../../../../assets/icons/useIcons';
import AsideModal from '../../../../components/AsideModal';
import ConsentWebCode from './ConsentWebCode';
import ConsentWebTable from './ConsentWebTable';

const ConsentWebhook = () => {
  const [openModal, setOpenModal] = useState('');
  const [copied, setCopied] = useState(false);

  function handleClose() {
    setOpenModal('');
  }

  const modalLookUp = {
    showInfoNpn: {
      component: <ShowNpnInfo handleClose={handleClose} />,
      title: 'Webhook Result',
    },
  };

  const handleCopy = (copyText) => {
    navigator.clipboard
      .writeText(copyText)
      .then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 1000);
      })
      .catch((err) => {
        console.error('Failed to copy text: ', err);
      });
  };

  return (
    <div className="d--flex flex--column gap--lg">
      <div className="d--flex gap--lg w--full h--full flex--column-xs">
        <div className="webhook w--full radius--sm border-full--black-100 p--lg h--full  h-min--440 h-min--auto-xs">
          <div className="d--flex gap--xl flex--column">
            <div className="d--flex gap--md flex--column ">
              <div className=" font--md font--600">
                Configure this webhook URL in your app or code
              </div>
              <div className="d--flex gap--md p-t---md h-min--60 radius--sm border-full--black-100 word-break--all p--md position--relative">
                {/* {`https://conxent-api-dev.nextere.com/webhook/create`} */}
                {`https://conxent-api.nextere.com/webhook/create`}
                <div
                  className="position--absolute top---13  right---4 c--pointer text--black-600 bg--white"
                  onClick={() =>
                    // handleCopy(
                    //   `https://conxent-api-dev.nextere.com/webhook/create`,
                    // )
                    handleCopy(`https://conxent-api.nextere.com/webhook/create`)
                  }
                >
                  {copied ? <TickCircleIcon /> : <CopyIcon />}
                </div>
              </div>
              {/* ------ */}
              {/* <div className="d--flex justify-content--end w--full">
                <div className="w-max--150 d--flex w--full bg--primary border-full--primary radius--md ">
                  {webhoookModeArr.map(({ type, label }) => (
                    <Button
                      key={type}
                      type="button"
                      btnClasses="btn btn--p-0"
                      color="black"
                      borderColor="primary"
                      isOutline
                      size="xs"
                      variant={
                        type === user?.consentWebhookMode ? 'white' : 'primary'
                      }
                      onClick={() => {
                        if (type !== user?.consentWebhookMode) {
                          mutate({ webhookType: 'CONSENT', webhookMode: type });
                        }
                      }}
                      disabled={
                        type !== user?.consentWebhookMode ? isLoading : false
                      }
                    >
                      {label}
                    </Button>
                  ))}
                </div>
              </div> */}
            </div>
          </div>
          {openModal ? (
            <AsideModal
              handleClose={handleClose}
              title={modalLookUp?.[openModal]?.title}
              footerComponent={null}
            >
              {modalLookUp?.[openModal]?.component}
            </AsideModal>
          ) : null}
        </div>

        <ConsentWebCode />
      </div>

      <ConsentWebTable />
    </div>
  );
};

export default ConsentWebhook;
