export const API_ROUTES = {
  CREATE_CONSENT: {
    METHOD: 'POST',
    URL: 'webhook/create',
  },
  AGENT_CONSENT_LIST: {
    METHOD: 'GET',
    URL: 'webhook/agent-consent-list',
  },
};
