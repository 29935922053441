import React from 'react';
import Button from '../../components/Button';
import useIcons from '../../assets/icons/useIcons';
import { useNavigate } from 'react-router-dom';
import FormInput from '../../components/FormInput';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import nextereLogo from '../../assets/images/nextere-logo.png';
import { email } from '../../helpers/yupValidations';
import { resetPassword } from '../SignUp/actions';
import { useAlert } from '../../hooks/useAlert';
import { useMutation } from '@tanstack/react-query';
import { submitBtnTxt } from '../../helpers/functions';

const validationSchema = yup.object().shape({
  email,
});
export default function ForgotPassword() {
  const { BackRoundIcon } = useIcons();
  const navigate = useNavigate();
  const { showAlert } = useAlert();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: { email: '' },
    mode: 'onSubmit',
  });

  const { mutate: mutateResetPassword, isLoading } = useMutation({
    mutationFn: resetPassword,
    mutationKey: 'resetPassword',
    onSuccess: ({ data }) => {
      showAlert({
        type: 'success',
        message: data?.data?.message ?? 'agent deleted successfully',
      });
      navigate('/');
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'danger', message: errMsg });
    },
  });
  async function onSubmit(_) {
    mutateResetPassword(_);
  }
  return (
    <div className="w--full  d--flex flex--column align-items--center justify-content--start loginPage bg--contrast overflow--y-auto">
      <div className="w--full h-min--60 d--flex justify-content--between align-items--center bg--white p-t--xs p-l--md p-r--md border-bottom--black-50">
        <img src={nextereLogo} alt="nextereLogo" className="w-max--150" />
      </div>
      <div className="w--full h--full p-t--xl d--flex align-items--center justify-content--center p-b--xl flex--column loginContent p-l--sm p-r--sm">
        {/* <div className="w--full gap--sm d--flex flex--column align-items--center justify-content--center m-b--sm">
        <h3 className="font--2xl text--primary ">
          <img src={nextereLogo} alt="nextereLogo" className="w-max--150" />
        </h3>
      </div> */}
        <div className="radius--md  gap--xl w-max--600 w--full bg--secondary p--xl z-index--xs bg--white box--shadow d--flex align-items--center justify-content--center flex--column gap--xs h-min--300">
          <div className="w--full">
            <div className="w--full gap--sm d--flex flex--column align-items--center justify-content--center">
              <span className="d--flex m-b--lg w--full justify-content--between align-items--center">
                <Button
                  onClick={() => navigate(-1)}
                  btnClasses="btn w-max--40 h-max--40 "
                  type="button"
                  variant="black-100"
                  icon={<BackRoundIcon width={40} height={40} />}
                  color="black"
                  rounded
                />
                <h3 className="font--2xl ">Forgot Password ?</h3>
                <span></span>
              </span>

              <form
                className="w--full d--flex flex--column gap--md"
                onSubmit={handleSubmit(onSubmit)}
                autoComplete="off"
              >
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <FormInput
                      {...field}
                      type="input"
                      label="Enter your email address, we will send you a recovery email."
                      placeholder="example@gmail.com"
                      error={errors?.email?.message}
                      height="40"
                    />
                  )}
                />

                <div className="form-group w--full">
                  <Button
                    type="submit"
                    btnClasses="btn"
                    disabled={isLoading}
                    size="md"
                    variant="primary"
                    color="white"
                  >
                    {submitBtnTxt(isLoading)}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
