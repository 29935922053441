import React, { useState } from 'react';
import FormInput from '../../../../../components/FormInput';
import { Controller, useForm } from 'react-hook-form';
import Button from '../../../../../components/Button';
import { useAuth } from '../../../../../hooks/useAuth';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { submitBtnTxt } from '../../../../../helpers/functions';
import { createWebhookObj } from '../../../actions';

const defaultValues = {
  agentFirstname: '',
  agentLastname: '',
  agentEmail: '',
  agentPhone: '',
  agentNPN: '',

  consumerFirstname: '',
  consumerLastname: '',
  consumerEmail: '',
  consumerPhone: '',
  consumerDob: '',

  subject: '',
  claimType: '',
  policyId: '',
  policyEffectiveDate: '',
  enrollmentYear: '',
  description: '',
};
const CodeWebhook = () => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  const [viewJsonFormat, setViewJsonFormat] = useState(false);

  const { control, handleSubmit, reset, getValues } = useForm({
    defaultValues,
    mode: 'onSubmit',
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: createWebhookObj,
    mutationKey: 'createWebhookObj',
    onSuccess: (data) => {
      reset(defaultValues);
      queryClient.invalidateQueries(['getWebhookList']);
    },
  });

  const onSubmit = (_) => mutate({ ..._, id: user?.webhook });

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
      className={`w--full preeCode ${
        viewJsonFormat ? 'bg--black text--white' : 'bg--black-25'
      }  border-full--black-100 radius--sm p--sm h-min--440 h-max--440 overflow--auto`}
    >
      <div className="d--flex justify-content--end align-items--center gap--sm">
        <Button
          variant="primary"
          type="button"
          color="black"
          btnClasses="btn w-max--150 white-space--nowrap"
          disabled={isLoading}
          onClick={() => {
            setViewJsonFormat((p) => !p);
          }}
        >
          {!viewJsonFormat ? 'View Json Format' : 'Back'}
        </Button>
        <Button
          variant="primary"
          color="black"
          btnClasses="btn w-max--120 "
          disabled={isLoading}
        >
          {submitBtnTxt(isLoading)}
        </Button>
      </div>
      {viewJsonFormat ? (
        <pre>
          <code>{JSON.stringify(getValues(), null, 2)}</code>
        </pre>
      ) : (
        <div className="d--flex flex--column gap--md w--auto p-l--md ">
          <div className="">&#123;</div>
          <div className="d--flex gap--md text--danger align-items--center ">
            <div className="d--flex font--2sm align-items--center fontF">
              <span className="text--black-200 d--flex">............</span>"
              &nbsp;agentFirstname &nbsp;" &nbsp; :
            </div>
            <div className="d--flex font--2sm ">
              "
              <Controller
                name="agentFirstname"
                control={control}
                render={({ field }) => (
                  <FormInput
                    {...field}
                    extraClasses="border--0 w-min--60 text--danger fontF"
                    height="16"
                    paddingLeft="xs"
                    paddingRight="xs"
                  />
                )}
              />
              "
            </div>
          </div>
          <div className="d--flex gap--md text--danger align-items--center ">
            <div className="d--flex font--2sm align-items--center fontF">
              <span className="text--black-200 d--flex">............</span>"
              &nbsp;agentLastname &nbsp;" &nbsp; :
            </div>
            <div className="d--flex font--2sm ">
              "
              <Controller
                name="agentLastname"
                control={control}
                render={({ field }) => (
                  <FormInput
                    {...field}
                    extraClasses="border--0 w-min--60 text--danger fontF"
                    height="16"
                    paddingLeft="xs"
                    paddingRight="xs"
                  />
                )}
              />
              "
            </div>
          </div>

          <div className="d--flex gap--md text--danger align-items--center">
            <div className="d--flex font--2sm align-items--center fontF">
              <span className="text--black-200 d--flex">............</span>"
              &nbsp;agentEmail &nbsp;" &nbsp; :
            </div>
            <div className="d--flex font--2sm ">
              "
              <Controller
                name="agentEmail"
                control={control}
                render={({ field }) => (
                  <FormInput
                    {...field}
                    extraClasses="border--0 w-min--60 text--danger fontF"
                    height="16"
                    paddingLeft="xs"
                    paddingRight="xs"
                  />
                )}
              />
              "
            </div>
          </div>
          <div className="d--flex gap--md text--danger align-items--center">
            <div className="d--flex font--2sm align-items--center fontF">
              <span className="text--black-200 d--flex">............</span>"
              &nbsp;agentPhone &nbsp;" &nbsp; :
            </div>
            <div className="d--flex font--2sm ">
              "
              <Controller
                name="agentPhone"
                control={control}
                render={({ field }) => (
                  <FormInput
                    {...field}
                    extraClasses="border--0 w-min--60 text--danger fontF"
                    height="16"
                    paddingLeft="xs"
                    paddingRight="xs"
                  />
                )}
              />
              "
            </div>
          </div>
          <div className="d--flex gap--md text--danger align-items--center">
            <div className="d--flex font--2sm align-items--center fontF">
              <span className="text--black-200 d--flex">............</span>"
              &nbsp;agentNpn &nbsp;" &nbsp; :
            </div>
            <div className="d--flex font--2sm ">
              "
              <Controller
                name="agentNPN"
                control={control}
                render={({ field }) => (
                  <FormInput
                    {...field}
                    type="number"
                    extraClasses="border--0 w-min--60 text--danger fontF"
                    height="16"
                    paddingLeft="xs"
                    paddingRight="xs"
                  />
                )}
              />
              "
            </div>
          </div>
          <div className="d--flex gap--md text--danger align-items--center ">
            <div className="d--flex font--2sm align-items--center fontF">
              <span className="text--black-200 d--flex">............</span>"
              &nbsp;consumerFirstname &nbsp;" &nbsp; :
            </div>
            <div className="d--flex font--2sm ">
              "
              <Controller
                name="consumerFirstname"
                control={control}
                render={({ field }) => (
                  <FormInput
                    {...field}
                    extraClasses="border--0 w-min--60 text--danger fontF"
                    height="16"
                    paddingLeft="xs"
                    paddingRight="xs"
                  />
                )}
              />
              "
            </div>
          </div>
          <div className="d--flex gap--md text--danger align-items--center ">
            <div className="d--flex font--2sm align-items--center fontF">
              <span className="text--black-200 d--flex">............</span>"
              &nbsp;consumerLastname &nbsp;" &nbsp; :
            </div>
            <div className="d--flex font--2sm ">
              "
              <Controller
                name="consumerLastname"
                control={control}
                render={({ field }) => (
                  <FormInput
                    {...field}
                    extraClasses="border--0 w-min--60 text--danger fontF"
                    height="16"
                    paddingLeft="xs"
                    paddingRight="xs"
                  />
                )}
              />
              "
            </div>
          </div>

          <div className="d--flex gap--md text--danger align-items--center">
            <div className="d--flex font--2sm align-items--center fontF">
              <span className="text--black-200 d--flex">............</span>"
              &nbsp;consumerEmail &nbsp;" &nbsp; :
            </div>
            <div className="d--flex font--2sm ">
              "{' '}
              <Controller
                name="consumerEmail"
                control={control}
                render={({ field }) => (
                  <FormInput
                    {...field}
                    extraClasses="border--0 w-min--60 text--danger fontF"
                    height="16"
                    paddingLeft="xs"
                    paddingRight="xs"
                  />
                )}
              />
              "
            </div>
          </div>
          <div className="d--flex gap--md text--danger align-items--center">
            <div className="d--flex font--2sm align-items--center fontF">
              <span className="text--black-200 d--flex">............</span>"
              &nbsp;consumerPhone &nbsp;" &nbsp; :
            </div>
            <div className="d--flex font--2sm ">
              "
              <Controller
                name="consumerPhone"
                control={control}
                render={({ field }) => (
                  <FormInput
                    {...field}
                    extraClasses="border--0 w-min--60 text--danger fontF"
                    height="16"
                    paddingLeft="xs"
                    paddingRight="xs"
                  />
                )}
              />
              "
            </div>
          </div>
          <div className="d--flex gap--md text--danger align-items--center">
            <div className="d--flex font--2sm align-items--center fontF">
              <span className="text--black-200 d--flex">............</span>"
              &nbsp;consumerDob &nbsp;" &nbsp; :
            </div>
            <div className="d--flex font--2sm ">
              "
              <Controller
                name="consumerDob"
                control={control}
                render={({ field }) => (
                  <FormInput
                    {...field}
                    extraClasses="border--0 w-min--60 text--danger fontF"
                    height="16"
                    paddingLeft="xs"
                    paddingRight="xs"
                  />
                )}
              />
              "
              <div className="font--2sm white-space--nowrap text--success m-l--md">
                Ex: 25-05-2000
              </div>
            </div>
          </div>
          <div className="d--flex gap--md text--danger align-items--center">
            <div className="d--flex font--2sm align-items--center fontF">
              <span className="text--black-200 d--flex">............</span>"
              &nbsp;Subject &nbsp;" &nbsp; :
            </div>
            <div className="d--flex font--2sm ">
              "
              <Controller
                name="subject"
                control={control}
                render={({ field }) => (
                  <FormInput
                    {...field}
                    extraClasses="border--0 w-min--60 text--danger fontF"
                    height="16"
                    paddingLeft="xs"
                    paddingRight="xs"
                  />
                )}
              />
              "
            </div>
          </div>
          <div className="d--flex gap--md text--danger align-items--center">
            <div className="d--flex font--2sm align-items--center fontF">
              <span className="text--black-200 d--flex">............</span>"
              &nbsp;Claim Type &nbsp;" &nbsp; :
            </div>
            <div className="d--flex font--2sm ">
              "
              <Controller
                name="claimType"
                control={control}
                render={({ field }) => (
                  <FormInput
                    {...field}
                    extraClasses="border--0 w-min--60 text--danger fontF"
                    height="16"
                    paddingLeft="xs"
                    paddingRight="xs"
                  />
                )}
              />
              "
              <div className="font--2sm white-space--nowrap text--success m-l--md">
                Ex: Consent / SSN
              </div>
            </div>
          </div>
          <div className="d--flex gap--md text--danger align-items--center">
            <div className="d--flex font--2sm align-items--center fontF">
              <span className="text--black-200 d--flex">............</span>"
              &nbsp;Policy ID &nbsp;" &nbsp; :
            </div>
            <div className="d--flex font--2sm ">
              "
              <Controller
                name="policyId"
                control={control}
                render={({ field }) => (
                  <FormInput
                    {...field}
                    extraClasses="border--0 w-min--60 text--danger fontF"
                    height="16"
                    paddingLeft="xs"
                    paddingRight="xs"
                  />
                )}
              />
              "
            </div>
          </div>
          <div className="d--flex gap--md text--danger align-items--center">
            <div className="d--flex font--2sm align-items--center fontF">
              <span className="text--black-200 d--flex">............</span>"
              &nbsp;Policy Effective Date &nbsp;" &nbsp; :
            </div>
            <div className="d--flex font--2sm ">
              "
              <Controller
                name="policyEffectiveDate"
                control={control}
                render={({ field }) => (
                  <FormInput
                    {...field}
                    extraClasses="border--0 w-min--60 text--danger fontF"
                    height="16"
                    paddingLeft="xs"
                    paddingRight="xs"
                  />
                )}
              />
              "
              <div className="font--2sm white-space--nowrap text--success m-l--md">
                Ex: 25-05-2000
              </div>
            </div>
          </div>
          <div className="d--flex gap--md text--danger align-items--center">
            <div className="d--flex font--2sm align-items--center fontF">
              <span className="text--black-200 d--flex">............</span>"
              &nbsp;Enrollment Year &nbsp;" &nbsp; :
            </div>
            <div className="d--flex font--2sm ">
              "
              <Controller
                name="enrollmentYear"
                control={control}
                render={({ field }) => (
                  <FormInput
                    {...field}
                    extraClasses="border--0 w-min--60 text--danger fontF"
                    height="16"
                    paddingLeft="xs"
                    paddingRight="xs"
                  />
                )}
              />
              "
              <div className="font--2sm white-space--nowrap text--success m-l--md">
                Ex: 2024 / 2025 / 2026
              </div>
            </div>
          </div>
          <div className="d--flex gap--md text--danger align-items--center">
            <div className="d--flex font--2sm align-items--center fontF">
              <span className="text--black-200 d--flex">............</span>"
              &nbsp;Description &nbsp;" &nbsp; :
            </div>
            <div className="d--flex font--2sm ">
              "
              <Controller
                name="description"
                control={control}
                render={({ field }) => (
                  <FormInput
                    {...field}
                    extraClasses="border--0 w-min--60 text--danger fontF"
                    height="16"
                    paddingLeft="xs"
                    paddingRight="xs"
                  />
                )}
              />
              "
            </div>
          </div>

          <div>&#125;</div>
        </div>
      )}
    </form>
  );
};

export default CodeWebhook;
