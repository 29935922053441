import { useCallback, useState } from 'react';
import Button from '../../../components/Button';
import TableManager from '../../../components/TableManager';
import { agencyStatusLookup } from '../constants';
import AsideModal from '../../../components/AsideModal';
import SendInvites from '../Modal/SendInvites';
import { getAgentInviteList } from '../../../api';
import { convertDateFunction } from '../../../helpers/functions';
import ActionConfirmation from '../../../components/ActionConfirmation';

const AgencyInvitation = () => {
  const [openModal, setOpenModal] = useState(false);

  const sendColumns = [
    {
      accessorKey: 'agentDetail',
      header: () => 'Agent Name',
      cell: ({ getValue }) =>
        `${getValue()?.firstName} ${getValue()?.lastName}`,
    },
    {
      accessorKey: 'agentDetail.phone',
      header: () => 'Agent Phone',
    },
    {
      accessorKey: 'agentDetail.email',
      header: () => 'Agent Email',
    },
    {
      accessorKey: 'agentDetail.NPN',
      header: () => 'Agent NPN',
    },
    {
      accessorKey: 'createdAt',
      header: () => 'Invite date',
      cell: ({ getValue }) => convertDateFunction(getValue()),
    },
    {
      accessorKey: 'agentInvitationStatus',
      header: () => 'Status',
      cell: ({ getValue }) => {
        return (
          <span className={agencyStatusLookup?.[getValue()]?.class}>
            {agencyStatusLookup?.[getValue()]?.text}
          </span>
        );
      },
    },
    {
      accessorKey: 'action',
      header: () => 'Action',
      cell: ({ row }) => {
        const rowData = row?.original;
        if (rowData?.agentInvitationStatus === 'I')
          return (
            <div className="d--flex gap--sm justify-content--center align-items--center">
              <ActionConfirmation
                message="Are you sure you want to Resend Invitation?"
                // onConfirm={() => handleUpdateStatus('A', rowData)}
              >
                <Button
                  btnClasses="btn  "
                  variant="success-100"
                  color="success"
                  size="sm"
                >
                  Resend Invitation
                </Button>
              </ActionConfirmation>
            </div>
          );
      },
      meta: {
        textAlign: 'center',
        width: '8%',
      },
    },
  ];
  const tableActions = [
    {
      id: 1,
      access: true,
      component: (
        <Button
          btnClasses="btn w-min--150 border-full--primary bg--primary-200 text--black"
          variant="transprent"
          color="black"
          isOutline
          onClick={() => {
            setOpenModal(true);
            // setEditMemberInstance(initialEditInstance);
          }}
        >
          Send Invites
        </Button>
      ),
    },
  ];
  const handleClose = useCallback(() => {
    setOpenModal(false);
  }, []);
  return (
    <>
      <div className="w--full d--flex flex--column  invitationPage">
        <TableManager
          {...{
            fetcherKey: 'getAgentInviteList',
            fetcherFn: getAgentInviteList,
            shouldFilter: false,
            name: 'Invites',
            columns: sendColumns,
            tableActions,
            // staticData: agencyStaticData,
          }}
        />
      </div>
      {openModal ? (
        <AsideModal
          handleClose={handleClose}
          title="Send Invites"
          footerComponent={null}
        >
          <SendInvites handleClose={handleClose} />
        </AsideModal>
      ) : null}
    </>
  );
};

export default AgencyInvitation;
