import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import FormInput from '../../../../../components/FormInput';
import PhoneInput from 'react-phone-number-input';
import { updateAgent } from '../../../../../api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuth } from '../../../../../hooks/useAuth';
import * as yup from 'yup';
import {
  domainValidation,
  email,
  firstName,
  lastName,
  npn,
  phone,
  ssn,
} from '../../../../../helpers/yupValidations';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAlert } from '../../../../../hooks/useAlert';
import Button from '../../../../../components/Button';
import Agencies from './Agencies';
import { MEMBER } from '../../../../../helpers/constants';
import { UserFill } from '../../../../../assets/icons/useIcons';
import useUploadFile from '../../../../../hooks/apiHooks';

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  agentWebsite: '',
  // NPN: '',
  // SSN: '',
};
const validationSchema = yup.object().shape({
  firstName,
  lastName,
  email,
  phone,
  agentWebsite: domainValidation,
  // NPN: npn,

  // SSN: ssn
});

export default function AgentForm() {
  const { user } = useAuth();
  const { showAlert } = useAlert();
  const { uploadMedia, isLoading: uploadMediaLoading } = useUploadFile();
  const [file, setFile] = useState('');

  const queryClient = useQueryClient();
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'onSubmit',
  });

  const { mutate, isLoading } = useMutation({
    mutationKey: ['updateAgent'],
    mutationFn: updateAgent,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ['getUserDetails'],
        exact: false,
      });
      showAlert({
        type: 'success',
        message: data?.data?.data?.message || 'Profile updated successfully"',
      });
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'danger', message: errMsg });
    },
  });

  const onSubmit = ({ NPN, ..._ }) => {
    mutate(_);
  };

  useEffect(() => {
    if (user) {
      const {
        firstName = '',
        lastName = '',
        email = '',
        phone = '',
        NPN = '',
        agentWebsite = '',
        // SSN = '',
      } = user || {};
      reset({
        firstName,
        lastName,
        email,
        phone,
        NPN,
        agentWebsite,
        // SSN,
      });
    }
  }, [user]);
  return (
    <div className="w--full d--flex flex--column gap--lg">
      {/* <div className="label--control font--sm font--500 m-b--xs text--secondary bg--secondary-100 border-full--secondary-100 p-t--sm p-b--sm p-r--md p-l--md radius--sm  m-t--md m-b--lg">Edit Information</div> */}
      <div className="d--flex gap--md flex--column border-full--black-100 bg--white p--lg radius--sm">
        <form
          className="w--full d--flex flex--column gap--lg"
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off"
        >
          <div className=" d--flex flex--column gap--lg ">
            <div className="w--full d--flex justify-content--between">
              <div className="d--flex gap--md align-items--center w--full">
                <label
                  htmlFor="upload_img"
                  className="w-min--75 h-min--75 w-max--75 h-max--75 radius--full bg--black-25 p--sm d--flex align-items--center justify-content--center c--pointer"
                >
                  <input
                    type="file"
                    accept="image/png,image/jpg"
                    hidden
                    onChange={(e) => {
                      setFile(e.target.files[0]);
                    }}
                    id="upload_img"
                  />
                  <div className="w-min--60 h-min--60 w-max--60 h-max--60 radius--full bg--black-50 p--sm d--flex align-items--center justify-content--center">
                    {`${process.env.REACT_APP_BASE_API_URL}/media/${user?._id}/profile/${user?.profilePic}` ? (
                      <img
                        src={
                          file
                            ? URL.createObjectURL(file)
                            : `${process.env.REACT_APP_BASE_API_URL}/media/${user?._id}/profile/${user?.profilePic}`
                        }
                        alt="Profile Pic"
                        className="w--full h--full object-cover"
                        width={40}
                      />
                    ) : (
                      <UserFill width={30} height={30} />
                    )}
                  </div>
                </label>
                <div className="d--flex flex--column gap--xs ">
                  {/* <div className="font--sm font--600">Update photo</div> */}
                  <Button
                    btnClasses="btn  w-max--140 font--2sm"
                    variant="primary-200"
                    color="black"
                    disabled={uploadMediaLoading || !file}
                    type="button"
                    size="sm"
                    borderColor="primary"
                    isOutline
                    rounded
                    onClick={async () => {
                      const res = await uploadMedia({
                        file,
                        type: 'profile',
                      });
                      setValue('profilePic', res.fileName);

                      handleSubmit(onSubmit)();
                    }}
                  >
                    {uploadMediaLoading ? 'Please wait...' : 'Upload Photo'}
                  </Button>
                  <div className="font--xs text--black-600">
                    PNG or JPG max size of 5MB
                  </div>
                </div>
              </div>
            </div>
            <div className="d--flex gap--md flex--column-xs">
              <div className="w--full">
                <Controller
                  name="firstName"
                  control={control}
                  render={({ field }) => (
                    <FormInput
                      {...field}
                      type="text"
                      label="First Name"
                      placeholder="Enter your first name"
                      maxLength={30}
                      error={errors?.firstName?.message}
                    />
                  )}
                />
              </div>
              <div className="w--full">
                <Controller
                  name="lastName"
                  control={control}
                  render={({ field }) => (
                    <FormInput
                      {...field}
                      type="text"
                      label="Last Name"
                      placeholder="Enter your last name"
                      maxLength={30}
                      error={errors?.lastName?.message}
                    />
                  )}
                />
              </div>
              <div className="w--full">
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <FormInput
                      {...field}
                      type="text"
                      label="Email"
                      placeholder="Enter your email address"
                      error={errors?.email?.message}
                      disabled={true}
                      extraClasses="c--not-allowed"
                    />
                  )}
                />
              </div>
            </div>

            <div className="d--flex gap--md flex--column-xs">
              <div className="w--full">
                <label
                  className={`label--control font--sm font--500 m-b--sm d--inline-block ${
                    errors?.phone?.message ? 'text--danger' : 'text--grey'
                  }`}
                >
                  {errors?.phone?.message ?? 'Phone'}
                </label>
                <Controller
                  name="phone"
                  control={control}
                  render={({ field }) => (
                    <PhoneInput
                      {...field}
                      control={control}
                      defaultCountry={watch('country')}
                      international={true}
                      addInternationalOption={false}
                      withCountryCallingCode={true}
                      countryCallingCodeEditable={false}
                      placeholder="Enter your phone"
                      internationalIcon={() => null}
                      flagComponent={() => null}
                      countrySelectComponent={() => null}
                      value={getValues(`phone`)}
                      limitMaxLength={true}
                      onChange={(phone) =>
                        setValue(`phone`, phone, {
                          shouldValidate: true,
                        })
                      }
                      className={`form--control w--full h-min--36  radius--sm p-l--md p-r--md  ${
                        errors?.phone?.message
                          ? 'border-full--danger'
                          : 'border-full--black-200'
                      }`}
                    />
                  )}
                />
              </div>
              <div className="w--full">
                <Controller
                  name="NPN"
                  control={control}
                  render={({ field }) => {
                    const { onChange } = field;
                    return (
                      <FormInput
                        {...field}
                        onChange={(e) => {
                          if (`${e.target.value}`?.length <= 12) {
                            onChange(e.target.value);
                          } else {
                            return;
                          }
                        }}
                        type="number"
                        label="NPN"
                        placeholder="Enter npn"
                        maxLength="12"
                        error={errors?.NPN?.message}
                        onKeyDown={(e) => {
                          if (['e', '+', '-'].includes(e.key))
                            e.preventDefault();
                        }}
                        disabled
                        extraClasses="c--not-allowed"
                      />
                    );
                  }}
                />
              </div>
              <div className="w--full">
                <Controller
                  name="agentWebsite"
                  control={control}
                  render={({ field }) => (
                    <FormInput
                      {...field}
                      label="Agent Website"
                      height="40"
                      required={false}
                      error={errors?.agentWebsite?.message}
                    />
                  )}
                />
              </div>
            </div>
            <div className="w--full d--flex align-items--center justify-content--end">
              <Button
                btnClasses="btn  w-max--140 text--black"
                variant="primary"
                color="black"
                disabled={isLoading}
                type="submit"
              >
                {isLoading ? 'Please wait...' : 'Update Profile'}
              </Button>
            </div>
          </div>
        </form>
      </div>

      {/* {userRole !== MEMBER && <Agencies />} */}
    </div>
  );
}
