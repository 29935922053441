import React, { useEffect, useState } from 'react';
import useIcons from '../../../assets/icons/useIcons';
import CameraCapture from '../CameraCapture';
import { getOS } from '../../../helpers/functions';

const DocumentVerification = ({ setDocumentData = () => null }) => {
  const { FileIcon, CameraIcon } = useIcons();
  const [document, setDocument] = useState(null);
  const [isCameraEnabled, setIsCameraEnabled] = useState(false);

  function startCameraHandler() {
    setIsCameraEnabled(true);
  }

  function handleDocumentUpload(e) {
    const file = e.target.files[0];
    e.target.value = null;
    if (file) {
      const reader = new FileReader();
      reader.onloadend = function () {
        setDocument(reader.result);
        setDocumentData(reader.result);
      };
      reader.readAsDataURL(file);
    }
  }

  return (
    <>
      <div className="w--full verification-header d--flex align-items--center justify-content--center p-t--md p-l--sm p-r--sm m-b--sm">
        <div className="font--lg font--600 m-b--sm">Document Verification</div>
      </div>

      {isCameraEnabled ? (
        <CameraCapture
          onCameraCapture={(data) => {
            setDocument(data);
            setIsCameraEnabled(false);
            setDocumentData(data);
          }}
        />
      ) : (
        <>
          <div className="w--full  radius--md p--md gap--md d--flex flex--column align-items--center h-max--250 h--full justify-content--between p--lg">
            {getOS() && ['windows', 'macos'].includes(getOS()) ? (
              <>
                <div
                  onClick={startCameraHandler}
                  className="w--full gap--sm border-full--primary bg--primary-100 text--black  radius--full h-min--60 d--flex align-items--center justify-content--center font--700 font--lg c--pointer"
                >
                  <CameraIcon /> Capture document photo
                </div>
                <div className="w--full d--flex flex--column justify-content--center align-items--center w-max--200 mx--auto position--relative">
                  <div className="border-bottom--black-100 position--absolute top--15 h-min--2 w--full"></div>
                  <div className="bg--white p--sm z-index--sm font--md font--600 text--black-600">
                    OR
                  </div>
                </div>
              </>
            ) : null}

            <label
              htmlFor="verification_document"
              className="w--full border-full--primary bg--primary text--black gap--sm radius--full h-min--60 d--flex align-items--center justify-content--center font--600 font--md c--pointer"
            >
              <input
                type="file"
                name="verification_document"
                id="verification_document"
                accept="image/*"
                hidden
                onChange={handleDocumentUpload}
              />
              <FileIcon />{' '}
              {['windows', 'macos'].includes(getOS())
                ? 'Upload Document'
                : 'Upload/Capture Document'}
            </label>
          </div>
        </>
      )}
    </>
  );
};

export default DocumentVerification;
