import { apiClient } from '../../api/axios';
import { API_ROUTES } from './constants';

export const createWebhookConsentObj = (data) => {
  return apiClient({
    method: API_ROUTES.CREATE_CONSENT.METHOD,
    url: API_ROUTES.CREATE_CONSENT.URL,
    // url: `${API_ROUTES.CREATE_CONSENT.URL}${id}`,
    data: data,
  });
};
export const agentConsenList = (data) => {
  return apiClient({
    method: API_ROUTES.AGENT_CONSENT_LIST.METHOD,
    url: API_ROUTES.AGENT_CONSENT_LIST.URL,
    data,
  });
};
