import React, { useCallback, useState } from 'react';
import TableManager from '../../components/TableManager';
import { getMemberList } from '../Members/actions';
import Button from '../../components/Button';
import AsideModal from '../../components/AsideModal';

const RecieveInvitation = () => {
  const [openModal, setOpenModal] = useState(false);

  const columns = [
    {
      accessorKey: 'templateName',
      header: () => 'Agency Name',
    },
    {
      accessorKey: 'phone',
      header: () => 'Agency Phone',
    },
    {
      accessorKey: 'templateName',
      header: () => 'Agency Email',
    },
    {
      accessorKey: 'templateName',
      header: () => 'Invite recieved date',
    },
    {
      accessorKey: 'templateName',
      header: () => 'Action',
    },
  ];
  const tableActions = [
    {
      id: 1,
      access: true,
      component: (
        <Button
          btnClasses="btn w-min--150"
          variant="primary"
          isOutline
          onClick={() => {
            setOpenModal(true);
            // setEditMemberInstance(initialEditInstance);
          }}
          size="sm"
        >
          Add Claim
        </Button>
      ),
    },
  ];
  const handleClose = useCallback(() => {
    setOpenModal(false);
  }, []);
  return (
    <>
      {' '}
      <div className="w--full d--flex flex--column gap--lg memberPage">
        <div className="w--full thead-doubble-tr">
          <TableManager
            {...{
              fetcherKey: 'getMemberList',
              fetcherFn: getMemberList,
              shouldFilter: false,
              name: 'RecieveInvitation',
              columns: columns,
              tableActions,
              selectCBForTableData: (tblData) => tblData?.data?.data?.memberResponseData,
            }}
          />
        </div>
      </div>
      {openModal ? <AsideModal handleClose={handleClose} title={`${true ? 'Add' : 'Edit'} Consumer`} footerComponent={null}></AsideModal> : null}
    </>
  );
};

export default RecieveInvitation;
