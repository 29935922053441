import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Button from '../../../../components/Button';
import { submitBtnTxt } from '../../../../helpers/functions';
import FormInput from '../../../../components/FormInput';
import { createWebhookConsentObj } from '../../actions';

const defaultValues = {
  apiKey: '',
  consumerFirstname: '',
  consumerLastname: '',
  consumerEmail: '',
  consumerPhone: '',
  docType: '',
};
const ConsentWebCode = () => {
  const queryClient = useQueryClient();
  const [viewJsonFormat, setViewJsonFormat] = useState(false);

  const { control, handleSubmit, reset, getValues } = useForm({
    defaultValues,
    mode: 'onSubmit',
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: createWebhookConsentObj,
    mutationKey: 'createWebhookConsentObj',
    onSuccess: (data) => {
      reset(defaultValues);
      queryClient.invalidateQueries(['agentConsenList']);
    },
  });

  const onSubmit = (_) => {
    mutate(_);
    // mutate({ ..._, id: user?.consentWebhookUrl });
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
      className={`w--full preeCode ${
        viewJsonFormat ? 'bg--black text--white' : 'bg--black-25'
      }  border-full--black-100 radius--sm p--sm h-min--440 h-max--440 overflow--auto`}
    >
      <div className="d--flex justify-content--end align-items--center gap--sm">
        <Button
          variant="primary"
          type="button"
          color="black"
          btnClasses="btn w-max--150 white-space--nowrap"
          onClick={() => {
            setViewJsonFormat((p) => !p);
          }}
        >
          {!viewJsonFormat ? 'View Json Format' : 'Back'}
        </Button>
        <Button
          variant="primary"
          color="black"
          btnClasses="btn w-max--120 "
          disabled={isLoading}
        >
          {submitBtnTxt(isLoading)}
        </Button>
      </div>
      {viewJsonFormat ? (
        <pre>
          <code>{JSON.stringify(getValues(), null, 2)}</code>
        </pre>
      ) : (
        <div className="d--flex flex--column gap--md w--auto p-l--md ">
          <div className="">&#123;</div>
          <div className="d--flex gap--md text--danger align-items--center">
            <div className="d--flex font--2sm align-items--center fontF">
              <span className="text--black-200 d--flex">............</span>"
              &nbsp;apiKey &nbsp;" &nbsp; :
            </div>
            <div className="d--flex font--2sm ">
              "
              <Controller
                name="apiKey"
                control={control}
                render={({ field }) => (
                  <FormInput
                    {...field}
                    extraClasses="border--0 w-min--60 text--danger fontF"
                    height="16"
                    paddingLeft="xs"
                    paddingRight="xs"
                  />
                )}
              />
              "
            </div>
          </div>
          <div className="d--flex gap--md text--danger align-items--center">
            <div className="d--flex font--2sm align-items--center fontF">
              <span className="text--black-200 d--flex">............</span>"
              &nbsp;consumerFirstname &nbsp;" &nbsp; :
            </div>
            <div className="d--flex font--2sm ">
              "{' '}
              <Controller
                name="consumerFirstname"
                control={control}
                render={({ field }) => (
                  <FormInput
                    {...field}
                    extraClasses="border--0 w-min--60 text--danger fontF"
                    height="16"
                    paddingLeft="xs"
                    paddingRight="xs"
                  />
                )}
              />
              "
            </div>
          </div>
          <div className="d--flex gap--md text--danger align-items--center">
            <div className="d--flex font--2sm align-items--center fontF">
              <span className="text--black-200 d--flex">............</span>"
              &nbsp;consumerLastname &nbsp;" &nbsp; :
            </div>
            <div className="d--flex font--2sm ">
              "{' '}
              <Controller
                name="consumerLastname"
                control={control}
                render={({ field }) => (
                  <FormInput
                    {...field}
                    extraClasses="border--0 w-min--60 text--danger fontF"
                    height="16"
                    paddingLeft="xs"
                    paddingRight="xs"
                  />
                )}
              />
              "
            </div>
          </div>
          <div className="d--flex gap--md text--danger align-items--center">
            <div className="d--flex font--2sm align-items--center fontF">
              <span className="text--black-200 d--flex">............</span>"
              &nbsp;consumerEmail &nbsp;" &nbsp; :
            </div>
            <div className="d--flex font--2sm ">
              "{' '}
              <Controller
                name="consumerEmail"
                control={control}
                render={({ field }) => (
                  <FormInput
                    {...field}
                    extraClasses="border--0 w-min--60 text--danger fontF"
                    height="16"
                    paddingLeft="xs"
                    paddingRight="xs"
                  />
                )}
              />
              "
            </div>
          </div>
          <div className="d--flex gap--md text--danger align-items--center">
            <div className="d--flex font--2sm align-items--center fontF">
              <span className="text--black-200 d--flex">............</span>"
              &nbsp;consumerPhone &nbsp;" &nbsp; :
            </div>
            <div className="d--flex font--2sm ">
              "
              <Controller
                name="consumerPhone"
                control={control}
                render={({ field }) => (
                  <FormInput
                    {...field}
                    extraClasses="border--0 w-min--60 text--danger fontF"
                    height="16"
                    paddingLeft="xs"
                    paddingRight="xs"
                  />
                )}
              />
              "
            </div>
          </div>

          <div className="d--flex gap--md text--danger align-items--center">
            <div className="d--flex font--2sm align-items--center fontF">
              <span className="text--black-200 d--flex">............</span>"
              &nbsp;docType &nbsp;" &nbsp; :
            </div>
            <div className="d--flex font--2sm ">
              "
              <Controller
                name="docType"
                control={control}
                render={({ field }) => (
                  <FormInput
                    {...field}
                    extraClasses="border--0 w-min--60 text--danger fontF"
                    height="16"
                    paddingLeft="xs"
                    paddingRight="xs"
                  />
                )}
              />
              "
              <div className="font--2sm white-space--nowrap text--success m-l--md">
                Ex: C := Consent , E := Eligibility
              </div>
            </div>
          </div>

          <div>&#125;</div>
        </div>
      )}
    </form>
  );
};

export default ConsentWebCode;
