import React, { useEffect, useState } from 'react';
import Button from '../../../../components/Button';
import ActionConfirmation from '../../../../components/ActionConfirmation';
import useIcons, { ApiIcon } from '../../../../assets/icons/useIcons';
import Switch from '../../../../components/Switch';
import { useAlert } from '../../../../hooks/useAlert';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getKey, manageKey } from '../../actions';
import {
  convertDateFunction,
  getObjectLength,
} from '../../../../helpers/functions';

const GenerateKey = () => {
  const [active, setActive] = useState(true);
  const { RefreshIcon } = useIcons();
  const { showAlert } = useAlert();

  const { data, refetch } = useQuery({
    queryKey: ['getKey'],
    queryFn: () => getKey(),
    select: (data) => data?.data?.data,
  });
  const { agentWebhookKey = '', status, updatedAt, createdAt } = data || {};

  const { mutate, isLoading } = useMutation({
    mutationFn: manageKey,
    mutationKey: 'manageKey',
    onSuccess: ({ data }) => {
      showAlert({ type: 'success', message: data?.data?.message });
      refetch();
      // queryclient.invalidateQueries({ queryKey: ['getAgentList'] });
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'danger', message: errMsg });
    },
  });
  useEffect(() => {
    if (data && getObjectLength(data)) {
      setActive(status);
    }
  }, [data]);

  return (
    <>
      {data && getObjectLength(data) ? (
        <div className="w--full d--flex border-full--black-100 radius--sm ">
          <div className="w--full d--flex flex--column">
            <div className="w--full font--sm font--700 text--dark border-bottom--black-100 h-min--50 d--flex align-items--center p-l--md">
              Status
            </div>
            <div className="w--full font--sm font--400 h-min--50 d--flex align-items--center p-l--md gap--sm">
              <Switch
                value={active}
                onChange={(e) => {
                  setActive(e.target.checked);
                  if (!isLoading) {
                    mutate({ type: 'STATUS_UPDATE', status: e.target.checked });
                  }
                }}
                checked={active}
                id="key"
              />
              <label className="label--control font--sm font--500  text--black ">
                Active
              </label>
            </div>
          </div>
          <div className="w--full d--flex flex--column">
            <div className="w--full font--sm font--700 text--dark border-bottom--black-100 h-min--50 d--flex align-items--center p-l--md">
              API Key
            </div>
            <div className="w--full font--sm font--400 h-min--50 d--flex align-items--center p-l--md">
              {agentWebhookKey}
            </div>
          </div>
          {/* <div className="w--full d--flex flex--column">
            <div className="w--full font--sm font--700 text--dark border-bottom--black-100 h-min--50 d--flex align-items--center p-l--md">
              Tags
            </div>
            <div className="w--full font--sm font--400 h-min--50 d--flex align-items--center p-l--md">
              Mongiya
            </div>
          </div> */}
          <div className="w--full d--flex flex--column">
            <div className="w--full font--sm font--700 text--dark border-bottom--black-100 h-min--50 d--flex align-items--center p-l--md">
              Created
            </div>
            <div className="w--full font--sm font--400 h-min--50 d--flex align-items--center p-l--md">
              {convertDateFunction(createdAt)}
            </div>
          </div>
          <div className="w--full d--flex flex--column ">
            <div className="w--full font--sm font--700 text--dark border-bottom--black-100 h-min--50 d--flex align-items--center p-l--md">
              Last Used
            </div>
            <div className="w--full font--sm font--400 h-min--50 d--flex align-items--center p-l--md">
              {convertDateFunction(updatedAt)}
            </div>
          </div>
          <div className="w--full d--flex flex--column w-max--150">
            <div className="w--full font--sm font--700 text--dark border-bottom--black-100 h-min--50 d--flex align-items--center p-l--md">
              Action
            </div>
            <div
              className="w--full font--sm font--400 h-min--50 d--flex align-items--center p-l--md gap--sm c--pointer"
              onClick={() => {
                if (!isLoading) {
                  mutate({ type: 'REGENERATE' });
                }
              }}
            >
              <div className="d--flex">
                <RefreshIcon width={20} height={20} />
              </div>
              <div className="white-space--nowrap"> Re-Generate</div>
            </div>
          </div>
        </div>
      ) : (
        <div className="w--full d--flex flex--column align-items--center justify-content--center gap--lg h--full manageKeyTab">
          <div className="w--full d--flex align-items--center justify-content--center text--black-200">
            <ApiIcon width={50} height={50} />
          </div>
          <ActionConfirmation
            message="Once created you will be able to view the API Key secret among other info in the table view."
            onConfirm={() => {
              if (!isLoading) {
                mutate({ type: 'CREATE' });
              }
            }}
            confirmBtnText="Create"
            btnText="Cancel"
          >
            <Button
              btnClasses="btn  w-max--140 text--black"
              variant="primary"
              color="black"
              type="button"
            >
              Create API Key
            </Button>
          </ActionConfirmation>
        </div>
      )}
    </>
  );
};

export default GenerateKey;
